// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.btn_ingredient {
    width: 100%;
    text-align: right;
    background-image: linear-gradient(-45deg, #039BE5 20%, #039BE5 47%, #00BCD4 61%);
    padding: 9px 11px;
    border-radius: 3px;
    color: white;
}
.table th {
    color: rgb(15, 15, 15) !important;
    background: rgb(249, 250, 251) !important;
}
.card-header {
    color: white !important;
    background: #2a77a8c7 !important;
}`, "",{"version":3,"sources":["webpack://./src/Pages/References/style.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,iBAAiB;IACjB,gFAAgF;IAChF,iBAAiB;IACjB,kBAAkB;IAClB,YAAY;AAChB;AACA;IACI,iCAAiC;IACjC,yCAAyC;AAC7C;AACA;IACI,uBAAuB;IACvB,gCAAgC;AACpC","sourcesContent":[".btn_ingredient {\n    width: 100%;\n    text-align: right;\n    background-image: linear-gradient(-45deg, #039BE5 20%, #039BE5 47%, #00BCD4 61%);\n    padding: 9px 11px;\n    border-radius: 3px;\n    color: white;\n}\n.table th {\n    color: rgb(15, 15, 15) !important;\n    background: rgb(249, 250, 251) !important;\n}\n.card-header {\n    color: white !important;\n    background: #2a77a8c7 !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
