import React, { useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import { Input } from 'reactstrap';
 // Assurez-vous de bien inclure votre fichier CSS

const BasicTable = ({ searchTerm,initialData }) => {
    const columns = [
    
        {
            name: <span className='font-weight-bold fs-15'>Ref #</span>,
            selector: row => row.srNo,
            sortable: true,
            className: 'table-header'
        },
        {
            name: <span className='font-weight-bold fs-14'>Name</span>,
            selector: row => (<span className='fontTable' >{row.title}</span>),
            sortable: true,
            className: 'table-header'
        },
        {
            name: <span className='font-weight-bold fs-14'>Date Creation</span>,
            selector: row => (<span className='fontTable'>{row.createDate}</span>),
            sortable: true,
            className: 'table-header'
        },
        {
            name: <span className='font-weight-bold fs-15'>Created by</span>,
            selector: row => (<span className='fontTable'>{row.createdBy}</span>),
            sortable: true,
            className: 'table-header'
        },
        {
            name: <span className='font-weight-bold fs-15'>Status</span>,
            sortable: true,
            selector: (cell) => {
                switch (cell.priority) {
                    case "Refused":
                        return <span className="badge bg-danger"> {cell.priority} </span>;
                    case "Created":
                        return <span className="badge bg-info"> {cell.priority} </span>;
                    case "Approved":
                        return <span className="badge bg-success"> {cell.priority} </span>;
                    default:
                        return <span className="badge bg-danger"> {cell.priority} </span>;
                }
            },
            className: 'table-header'
        },
        {
            name: <span className='font-weight-bold fs-13'>Action</span>,
            sortable: true,
            cell: row => (
                <div className="d-flex gap-2">
                    <div className="edit">
                        <button className="btn btn-outline-info waves-effect waves-light" data-bs-toggle="modal" data-bs-target="#showModal">
                            <i className="ri-pencil-fill align-bottom  "></i>
                        </button>
                    </div>
                    <div className="remove">
                        <button className="btn btn-outline-danger waves-effect waves-light" data-bs-toggle="modal" data-bs-target="#deleteRecordModal">
                            <i className="ri-delete-bin-fill align-bottom  "></i>
                        </button>
                    </div>
                </div>
            ),
            className: 'table-header'
        }
    ];

 

    const [filteredData, setFilteredData] = useState(initialData);

    useEffect(() => {
        setFilteredData(
            initialData.filter(item =>
                item.srNo.toLowerCase().includes(searchTerm.toLowerCase()) ||
                item.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
                item.createdBy.toLowerCase().includes(searchTerm.toLowerCase()) ||
                item.createDate.toLowerCase().includes(searchTerm.toLowerCase()) ||
                item.priority.toLowerCase().includes(searchTerm.toLowerCase())
            )
        );
    }, [searchTerm]);

    return (
        <DataTable
            columns={columns}
            data={filteredData}
            pagination
        />
    );
};

export { BasicTable };
