import React, { useState } from 'react';
import { Button, Card, CardBody, Col, Container, Nav, NavItem, NavLink, Row, TabContent, TabPane } from "reactstrap";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { BasicTable } from './datatableCom';
import { Link } from 'react-router-dom';
import classnames from "classnames";

const ListFormulaTemplate = () => {
    const [customActiveTab, setcustomActiveTab] = useState("1");
    const [searchTerm, setSearchTerm] = useState('');

    const toggleCustom = (tab) => {
        if (customActiveTab !== tab) {
            setcustomActiveTab(tab);
        }
    };
    const SterileData = [
        { srNo: "01", title: "TemplateName 1", createdBy: "Joseph Parker", createDate: "03/04/2024", priority: "Refused" },
        { srNo: "02", title: "TemplateName 2", createdBy: "Mary Rucker", createDate: "05/02/2024", priority: "Created" },
        { srNo: "03", title: "TemplateName 3", createdBy: "Tonya Noble", createDate: "27/04/2023", priority: "Approved" },
        { srNo: "04", title: "TemplateName 4", createdBy: "Freda", createDate: "16/08/2023", priority: "Created" },
        { srNo: "05", title: "TemplateName 5", createdBy: "Mary Rucker", createDate: "14/08/2023", priority: "Created" },
        { srNo: "06", title: "TemplateName 6", createdBy: "James Morris", createDate: "12/03/2023", priority: "Refused" },
        { srNo: "07", title: "TemplateName 7", createdBy: "Nathan Cole", createDate: "28/02/2023", priority: "Approved" },
        { srNo: "08", title: "TemplateName 8", createdBy: "Grace Coles", createDate: "07/01/2023", priority: "Refused" },
    ];
    const NonSterileData = [
        { srNo: "01", title: "TemplateName 1", createdBy: "Freda", createDate: "16/08/2023", priority: "Created" },
        { srNo: "02", title: "TemplateName 2", createdBy: "Mary Rucker", createDate: "14/08/2023", priority: "Created" },
        { srNo: "03", title: "TemplateName 3", createdBy: "James Morris", createDate: "12/03/2023", priority: "Refused" },
        { srNo: "04", title: "TemplateName 4", createdBy: "Nathan Cole", createDate: "28/02/2023", priority: "Approved" },
        { srNo: "05", title: "TemplateName 5", createdBy: "Grace Coles", createDate: "07/01/2023", priority: "Refused" },
    ];
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="Master Formula" breadcrumbItem="List of Master Formula" />

                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardBody>
                                    <div id="customerList">
                                        <Row className="g-4 mb-3">
                                            <Col className="col-sm-auto">
                                                <div className="d-flex gap-1">
                                                    <Button
                                                        color="primary"
                                                        className="add-btn"
                                                        tag={Link}
                                                        to="/create-template"
                                                        id="create-btn"
                                                    >
                                                        <i className="ri-add-line align-bottom me-1"></i>
                                                        Create New Master Formula
                                                    </Button>
                                                  
                                                </div>
                                            </Col>
                                        
                                        </Row>

                                        <Col lg={12}>
                                            <Nav tabs className="nav-tabs-custom nav-justified">
                                                <NavItem>
                                                    <NavLink
                                                        style={{ cursor: "pointer" }}
                                                        className={classnames({
                                                            active: customActiveTab === "1",
                                                        })}
                                                        onClick={() => {
                                                            toggleCustom("1");
                                                        }}
                                                    >
                                                        <span className="d-sm-block">
                                                            Formula Sterile
                                                        </span>
                                                    </NavLink>
                                                </NavItem>
                                                <NavItem>
                                                    <NavLink
                                                        style={{ cursor: "pointer" }}
                                                        className={classnames({
                                                            active: customActiveTab === "2",
                                                        })}
                                                        onClick={() => {
                                                            toggleCustom("2");
                                                        }}
                                                    >
                                                        <span className="d-sm-block">
                                                             Formula Non_Sterile
                                                        </span>
                                                    </NavLink>
                                                </NavItem>
                                            </Nav>

                                            <TabContent activeTab={customActiveTab} className="p-3 text-muted">
                                                <TabPane tabId="1">
                                                    <Row>
                                                    <Col className="col-sm">
                                                <div className="d-flex justify-content-sm-end">
                                                    <div className="search-box">
                                                        <input
                                                            type="text"
                                                            className="form-control search"
                                                            placeholder="Search..."
                                                            value={searchTerm}
                                                            onChange={e => setSearchTerm(e.target.value)}
                                                        />
                                                    </div>
                                                </div>
                                            </Col>
                                                        <Col sm="12">
                                                            <BasicTable searchTerm={searchTerm} initialData={SterileData} />
                                                        </Col>
                                                    </Row>
                                                </TabPane>
                                                <TabPane tabId="2">
                                                    <Row>
                                                    <Col className="col-sm">
                                                <div className="d-flex justify-content-sm-end">
                                                    <div className="search-box">
                                                        <input
                                                            type="text"
                                                            className="form-control search"
                                                            placeholder="Search..."
                                                            value={searchTerm}
                                                            onChange={e => setSearchTerm(e.target.value)}
                                                        />
                                                    </div>
                                                </div>
                                            </Col>
                                                        <Col sm="12">
                                                            <BasicTable searchTerm={searchTerm} initialData={NonSterileData} />
                                                        </Col>
                                                    </Row>
                                                </TabPane>
                                            </TabContent>
                                        </Col>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default ListFormulaTemplate;
