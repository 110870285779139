import React, { useState } from 'react';
import { Button, Card, CardBody, Col, Container, Row,} from "reactstrap";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { BasicTable } from './datatableCom';
import { Link } from 'react-router-dom';
import classnames from "classnames";

const ListUsers = () => {
    const [customActiveTab, setcustomActiveTab] = useState("1");
    const [searchTerm, setSearchTerm] = useState('');

    const toggleCustom = (tab) => {
        if (customActiveTab !== tab) {
            setcustomActiveTab(tab);
        }
    };
    const SterileData = [
        { srNo: "01", userName: "User Name 1", lastName: "Joseph ", firstName: "Parker", priority: "Disabled" },
        { srNo: "02", userName: "User Name 2", lastName: "Mary", firstName: " Rucker", priority: "Created" },
        { srNo: "03", userName: "User Name 3", lastName: "Tonya ", firstName: "Noble", priority: "Enabled" },
        { srNo: "04", userName: "User Name 4", lastName: "Freda", firstName: " Rucker", priority: "Created" },
        { srNo: "05", userName: "User Name 5", lastName: "Mary", firstName: " Rucker", priority: "Created" },
        { srNo: "06", userName: "User Name 6", lastName: "James ", firstName: "Morris", priority: "Disabled" },
        { srNo: "07", userName: "User Name 7", lastName: "Nathan ", firstName: "Cole", priority: "Enabled" },
        { srNo: "08", userName: "User Name 8", lastName: "Grace", firstName: " Coles", priority: "Disabled" },
    ];
  
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="Users Management" breadcrumbItem="List of Users Management" />

                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardBody>
                                    <div id="customerList">
                                        <Row className="g-4 mb-3">
                                            <Col className="col-sm-auto">
                                                <div className="d-flex gap-1">
                                                    <Button
                                                        color="primary"
                                                        className="add-btn"
                                                        tag={Link}
                                                        to="/create-user"
                                                        id="create-btn"
                                                    >
                                                        <i className="ri-add-line align-bottom me-1"></i>
                                                        Create New User
                                                    </Button>
                                                  
                                                </div>
                                            </Col>
                                            <Col className="col-sm">
                                                <div className="d-flex justify-content-sm-end">
                                                    <div className="search-box">
                                                        <input
                                                            type="text"
                                                            className="form-control search"
                                                            placeholder="Search..."
                                                            value={searchTerm}
                                                            onChange={e => setSearchTerm(e.target.value)}
                                                        />
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>

                                        <Col lg={12}>
                                          

                                           
                                              
                                                    <Row>
                                                  
                                                        <Col sm="12">
                                                        <div >
                                                            <BasicTable searchTerm={searchTerm} initialData={SterileData} />
                                                            </div>
                                                        </Col>
                                                    </Row>
                                              
                                                  
                                       
                                        </Col>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default ListUsers;
