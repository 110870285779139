import React, { useState, useEffect, useRef } from 'react';
import DataTable from 'react-data-table-component';
import { Input, Button, Card, CardBody, Col, UncontrolledAlert, Modal, } from 'reactstrap';
// Assurez-vous de bien inclure votre fichier CSS

const BasicTable = ({ searchTerm, initialData }) => {
    const [modal_standard, setmodal_standard] = useState(false);
 
    function tog_standard() {
        setmodal_standard(!modal_standard);
    }

    const columns = [
        // {
        //     name: <Input className="form-check-input" type="checkbox" name="checkAll" value="option1" />,
        //     cell: () => (<input className="form-check-input" type="checkbox" name="checkAll" value="option1" />),
        //     selector: row => row.checkbox, // Ajouter cette ligne pour éviter les erreurs
        //     sortable: false,
        //     grow: 0
        // },
        {
            name: <span className='font-weight-bold fs-15'>Ref #</span>,
            selector: row => row.srNo,
            sortable: true,
            className: 'table-header'
        },
        {
            name: <span className='font-weight-bold fs-14'>Name</span>,
            selector: row => (<span className='fontTable' >{row.title}</span>),
            sortable: false,
            className: 'table-header'
        },
        {
            name: <span className='font-weight-bold fs-14'>Date Creation</span>,
            selector: row => (<span className='fontTable'>{row.createDate}</span>),
            sortable: false,
            className: 'table-header'
        },
        {
            name: <span className='font-weight-bold fs-15'>Created by</span>,
            selector: row => (<span className='fontTable'>{row.createdBy}</span>),
            sortable: false,
            className: 'table-header'
        },
        {
            name: <span className='font-weight-bold fs-15'>Authorized by</span>,
            selector: row => (<span className='fontTable'>{row.authorizedBy}</span>),
            sortable: false,
            className: 'table-header'
        },
        {
            name: <span className='font-weight-bold fs-15'>Checked by</span>,
            selector: row => (<span className='fontTable'>{row.checkedBy}</span>),
            sortable: false,
            className: 'table-header'
        },
        {
            name: <span className='font-weight-bold fs-15'>Status</span>,
            sortable: false,
            selector: (cell) => {
                switch (cell.priority) {
                    case "Refused":
                        return <span className="badge bg-danger"> {cell.priority} </span>;
                    case "Created":
                        return <span className="badge bg-info"> {cell.priority} </span>;
                    case "Approved":
                        return <span className="badge bg-success"> {cell.priority} </span>;
                    default:
                        return <span className="badge bg-danger"> {cell.priority} </span>;
                }
            },
            className: 'table-header'
        },
        {
            name: <span className='font-weight-bold fs-13'>Action</span>,
            sortable: false,
            cell: row => (
                <div className="d-flex gap-2">
                    <div className="edit">
                        <button className="btn btn-outline-info waves-effect waves-light"   >
                            <i className="ri-pencil-fill align-bottom  "></i>
                        </button>
                    </div>
                    <div className="remove">
                        <button className="btn btn-outline-danger waves-effect waves-light" onClick={() => {
                            tog_standard();
                        }}>
                            <i className="ri-delete-bin-fill align-bottom  "></i>
                        </button>

                        <Modal isOpen={modal_standard} toggle={() => {
                                tog_standard(); }} centered >

                            <CardBody>
                                <div className="text-center">
                                    <div className="mb-4">
                                        <i className="mdi mdi-alert-circle-outline display-1 text-danger fs-30"></i>
                                    </div>
                                    <h4 className="alert-heading">
                                        Are you sure?
                                    </h4>
                                    <p className="mb-0">
                                        you wont be able to revert this !
                                        <br />   <br />
                                    </p>
                                </div>
                            </CardBody>

                            <div className="modal-footer text-center " style={{justifyContent:"center"}}>
                                <button  type="button"  onClick={() => {   tog_standard();  }} className="btn btn-primary "   data-dismiss="modal"  >
                                   Yes, delete it !
                                </button>
                                <button   type="button"  className="btn btn-danger "  onClick={() => {  tog_standard();  }}  >
                                   Cancel
                                </button>
                            </div>
                        </Modal>
                    </div>
                </div>
            ),
            className: 'table-header'
        }
    ];
    const [filteredData, setFilteredData] = useState(initialData);

    useEffect(() => {
        setFilteredData(
            initialData.filter(item =>
                item.srNo.toLowerCase().includes(searchTerm.toLowerCase()) ||
                item.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
                item.createdBy.toLowerCase().includes(searchTerm.toLowerCase()) ||
                item.createDate.toLowerCase().includes(searchTerm.toLowerCase()) ||
                item.priority.toLowerCase().includes(searchTerm.toLowerCase())
            )
        );
    }, [searchTerm]);

    return (
        <DataTable
            columns={columns}
            data={filteredData}
            pagination
        />
    );
};

export { BasicTable };
