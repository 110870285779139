import React, { useState, useEffect } from "react";
import { Label } from "reactstrap";
import { connect } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Select from "react-select";
import NotificationDropdown from "../../components/Common/TopbarDropdown/NotificationDropdown";
import axios from "axios";
import { BACKEND_URL , BACKEND_Externe_URL} from "../../config/const.js";
import { withTranslation } from "react-i18next";
//import images
import logoSm from "../../assets/images/logo-sm.png";
import logoDark from "../../assets/images/logo-dark.png";
// import logoLight from "../../assets/images/logo-light.png";
// Redux Store
import {
  showRightSidebarAction,
  toggleLeftmenu,
  changeSidebarType,
} from "../../store/actions";

const Header = (props) => {
  const [loading, setLoading] = useState(true);
  const [organizations, setOrganization] = useState([]);
  const [error, setError] = useState(null);
  const [selectedGroup, setselectedGroup] = useState({
    label: "Select organization",
    value: 0,
  });
  const navigate = useNavigate();

  const config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  };
  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        const response = await axios.get(`${BACKEND_Externe_URL}connectedUserInfo`, config);
       
        localStorage.setItem("user",JSON.stringify( response));
        console.log('data:',response);
        setLoading(false);
     
      } catch (error) {
        console.error("Error fetching profile data:", error.response ? error.response : error.message);
        setError("Failed to load profile data");
        setLoading(false);
      }
    };

    fetchUserProfile();
  }, []); // Empty array to fetch data only on component mount


  useEffect(() => {
    const fetchOrganization = async () => {
      try {
        const response = await axios.get(`${BACKEND_URL}api/user/organisations`, config);
        // Assuming response.data is an array of organizations
        const options = response.map((org) => ({
          label: org.name,
          value: org.id,
        }));
        setOrganization(options);
        setLoading(false);
      } catch (error) {
        setError("Failed to load organizations");
        setLoading(false);
      }
    };

    fetchOrganization();
  }, []);

  function handleSelectGroup(selectedOption) {
    setselectedGroup(selectedOption);
  }

  function tToggle() {
    const body = document.body;
    if (window.screen.width <= 998) {
      body.classList.toggle("sidebar-enable");
    } else {
      body.classList.toggle("vertical-collapsed");
      body.classList.toggle("sidebar-enable");
    }
  }

  const handleLogout = () => {
    navigate("/logout");
  };

  return (
    <React.Fragment>
      <header id="page-topbar">
        <div className="navbar-header">
          <div className="d-flex">
            <div className="navbar-brand-box text-center">
              <Link to="/" className="logo logo-dark">
                <span className="logo-sm">
                  <img src={logoSm} alt="logo-sm-dark" height="22" />
                </span>
                <span className="logo-lg">
                  <img src={logoDark} alt="logo-dark" height="57" />
                </span>
              </Link>

              <Link to="/" className="logo logo-light">
                <span className="logo-sm">
                  <img src={logoDark} alt="logo-sm-light" height="22" />
                </span>
                <span className="logo-lg">
                  <img src={logoDark} alt="logo-light" height="57" />
                </span>
              </Link>
            </div>

            <button
              type="button"
              className="btn btn-sm px-3 font-size-24 header-item waves-effect"
              id="vertical-menu-btn"
              onClick={tToggle}
            >
              <i className="ri-menu-2-line align-middle"></i>
            </button>
            <span className="logo-lg">
              <h4 style={{ color: "white", marginTop: 22 }}>LOGO Organisation</h4>
            </span>
          </div>

          <div className="d-flex align-items-center">
            <Label style={{ marginRight: "8px", color: "white" }}>
              Organisation:
            </Label>
            <form className="app-search d-none d-lg-block">
              {loading ? (
                <p>Loading organizations...</p>
              ) : error ? (
                <p>{error}</p>
              ) : (
                <Select
                  value={selectedGroup}
                  onChange={handleSelectGroup}
                  options={organizations}
                  classNamePrefix="select2-selection"
                  placeholder="Select an organization"
                />
              )}
            </form>

            <div className="dropdown d-none d-lg-inline-block ms-1">
              <button type="button" className="btn header-item noti-icon" data-toggle="translate">
                <i className="bx bx-globe" />
              </button>
            </div>

            <NotificationDropdown />

            <button
              type="button"
              onClick={handleLogout}
              className="btn header-item noti-icon right-bar-toggle waves-effect"
            >
              <i className="dripicons-enter"></i>
            </button>
          </div>
        </div>
      </header>
    </React.Fragment>
  );
};

const mapStatetoProps = (state) => {
  const { layoutType, showRightSidebar, leftMenu, leftSideBarType } = state.Layout;
  return { layoutType, showRightSidebar, leftMenu, leftSideBarType };
};

export default connect(mapStatetoProps, {
  showRightSidebarAction,
  toggleLeftmenu,
  changeSidebarType,
})(withTranslation()(Header));
