import { call, put, takeEvery } from "redux-saga/effects";
import axios from "axios";
// Login Redux States
import { LOGIN_USER, LOGOUT_USER } from "./actionTypes";
import { apiError, loginSuccess, logoutUserSuccess } from "./actions";
import { BACKEND_Externe_URL } from "../../../config/const";
//Include Both Helper File with needed methods
//import {  postFakeLogin,  postJwtLogin,  } from "../../../helpers/fakebackend_helper";

function* loginUser({ payload: { user, history } }) {
  try {
    console.log('start ',user);
    const response = yield call(axios.post, BACKEND_Externe_URL+"login", {
      username: user.username,
      password: user.password,
    });
    console.log('data ',response);
   
    localStorage.setItem("authUser", JSON.stringify(response.token));
    localStorage.setItem("token", response.token);
    localStorage.setItem("roles", JSON.stringify(response.roles));
    console.log('roles:',localStorage.getItem("roles"));
    yield put(loginSuccess(response.token));
    console.log('token ',response.token);
    history("/dashboard");
  } catch (error) {
    console.log('start ',error);
    yield put(apiError(error.message));
  }
}
// function* loginUser({ payload: { user, history } }) {
//   try {
// if (process.env.REACT_APP_DEFAULTAUTH === "jwt") {
//       const response = yield call(postJwtLogin, {
//         email: user.email,
//         password: user.password,
//       });
//       localStorage.setItem("authUser", JSON.stringify(response));
//       yield put(loginSuccess(response));
//     } else if (process.env.REACT_APP_DEFAULTAUTH === "fake") {
//       const response = yield call(postFakeLogin, {
//         email: user.email,
//         password: user.password,
//       });
//       localStorage.setItem("authUser", JSON.stringify(response));
//       yield put(loginSuccess(response));
//     }
//     history("/dashboard");
//   } catch (error) {
//     yield put(apiError(error));
//   }
// }

function* logoutUser() {
  try {
    localStorage.removeItem("authUser");
    localStorage.removeItem("token");
    localStorage.removeItem("roles");
    localStorage.removeItem("user");
    if (process.env.REACT_APP_DEFAULTAUTH !== "firebase") {
      yield put(logoutUserSuccess(LOGOUT_USER, true));
    }
  } catch (error) {
    yield put(apiError(LOGOUT_USER, error));
  }
}


function* authSaga() {
  yield takeEvery(LOGIN_USER, loginUser);
  yield takeEvery(LOGOUT_USER, logoutUser);
}

export default authSaga;
