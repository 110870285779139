// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.row-form {
    background: #fafafa;
    padding: 1%;
    border-radius: 7px;
    box-shadow: 1px 1px 5px #1f5185;
}
.logo-organization{
    width: 113px;
    height: 54px;
}
label,p, span,a, h5,h6 {
    color:#77797b;
    font-weight: 400;
}
.card-header {
    color: white !important;
    background: #2a77a8c7 !important;
}
#row-concentration{
margin-top: 8px;
}
.table th {
    color: white !important;
    background: rgb(29, 50, 79) !important;
}
.form-control:disabled {
    background-color: #b4cde145!important;
    opacity: 1;
}
.form-select:disabled {
    background-color: #ecf1f7 !important;
}
.td-disabled{
    background: #cecfcf !important;
}
.fixed-bottom-buttons {
    position: fixed !important;
    bottom: 0;
    right: 0;
    width: 100%;
    background-color: #fff; 
    z-index: 1000;
    box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1); 
    padding: 10px;
    text-align: right;
  }
  .btn-add{
    margin-left: 10%;
    padding: 4px 8px !important;
    border-radius: 6px;
    background: #9bc5f8 !important;
    color: white !important;
  }
  .btn-remove{
    margin-left: 10%;
    padding: 4px 8px !important;
    border-radius: 6px;
    background: #f3819b !important;
  }`, "",{"version":3,"sources":["webpack://./src/Pages/Template/style.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,WAAW;IACX,kBAAkB;IAClB,+BAA+B;AACnC;AACA;IACI,YAAY;IACZ,YAAY;AAChB;AACA;IACI,aAAa;IACb,gBAAgB;AACpB;AACA;IACI,uBAAuB;IACvB,gCAAgC;AACpC;AACA;AACA,eAAe;AACf;AACA;IACI,uBAAuB;IACvB,sCAAsC;AAC1C;AACA;IACI,qCAAqC;IACrC,UAAU;AACd;AACA;IACI,oCAAoC;AACxC;AACA;IACI,8BAA8B;AAClC;AACA;IACI,0BAA0B;IAC1B,SAAS;IACT,QAAQ;IACR,WAAW;IACX,sBAAsB;IACtB,aAAa;IACb,yCAAyC;IACzC,aAAa;IACb,iBAAiB;EACnB;EACA;IACE,gBAAgB;IAChB,2BAA2B;IAC3B,kBAAkB;IAClB,8BAA8B;IAC9B,uBAAuB;EACzB;EACA;IACE,gBAAgB;IAChB,2BAA2B;IAC3B,kBAAkB;IAClB,8BAA8B;EAChC","sourcesContent":[".row-form {\n    background: #fafafa;\n    padding: 1%;\n    border-radius: 7px;\n    box-shadow: 1px 1px 5px #1f5185;\n}\n.logo-organization{\n    width: 113px;\n    height: 54px;\n}\nlabel,p, span,a, h5,h6 {\n    color:#77797b;\n    font-weight: 400;\n}\n.card-header {\n    color: white !important;\n    background: #2a77a8c7 !important;\n}\n#row-concentration{\nmargin-top: 8px;\n}\n.table th {\n    color: white !important;\n    background: rgb(29, 50, 79) !important;\n}\n.form-control:disabled {\n    background-color: #b4cde145!important;\n    opacity: 1;\n}\n.form-select:disabled {\n    background-color: #ecf1f7 !important;\n}\n.td-disabled{\n    background: #cecfcf !important;\n}\n.fixed-bottom-buttons {\n    position: fixed !important;\n    bottom: 0;\n    right: 0;\n    width: 100%;\n    background-color: #fff; \n    z-index: 1000;\n    box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1); \n    padding: 10px;\n    text-align: right;\n  }\n  .btn-add{\n    margin-left: 10%;\n    padding: 4px 8px !important;\n    border-radius: 6px;\n    background: #9bc5f8 !important;\n    color: white !important;\n  }\n  .btn-remove{\n    margin-left: 10%;\n    padding: 4px 8px !important;\n    border-radius: 6px;\n    background: #f3819b !important;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
