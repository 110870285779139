import React from 'react';


import logolight from '../../assets/images/logo-light.png';
import logodark from '../../assets/images/logo-dark.png';

import { Container, Row, Col, Card, CardBody } from 'reactstrap';
import { Link } from 'react-router-dom';

const RecoverPassword = () => {
  document.title = "Recover Password | Quess Magistrale";
  return (
    <React.Fragment>
      <div className="bg-pattern" style={{ height: "100vh" }}>
        <div className="bg-overlay "></div>
        <div className="account-pages pt-5">
          <Container>
            <Row className="justify-content-center">
              <Col lg={6} md={8} xl={6}>
                <Card className='mt-5'>
                  <CardBody className="p-4">
                    <div className="">
                      <div className="text-center">
                        <Link to="/" className="">
                          <img src={logodark} alt="" height="24" className="auth-logo logo-dark mx-auto" />
                          <img src={logolight} alt="" height="24" className="auth-logo logo-light mx-auto" />
                        </Link>
                      </div>
                      <h4 className="font-size-18 text-muted mt-3 text-center">Reset Password</h4>
                      <section className="jss109">
                        <hr className="jss150" data-content="Reset your Password with Email." style={{ margin: "19px 4px 10px" }}>
                        </hr>
                      </section>
                      {/* <p className="mb-5 text-center"></p> */}
                      <form className="form-horizontal" action="#">
                        <Row>
                          <Col md={12}>
                            <div className="alert alert-warning alert-dismissible">
                                <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                                Enter your <b>Email</b> and instructions will be sent to you!
                            </div>


                            <div className="mt-4">
                              <label className="form-label" htmlFor="useremail">Email</label>
                              <input type="email" className="form-control" id="useremail" placeholder="Enter email" />
                            </div>
                            <div className="d-grid mt-4">
                              <a href='/dashboard' className="btn btn-primary waves-effect waves-light" type="submit">Send Email</a>
                            </div>
                            <div className="text-end mt-4">
                              <h5 className="font-size-14 "><span>FRENCH</span>/<span>ENGLISH</span> <i className="bx bx-globe" style={{fontSize:'18px'}}/></h5>
                            </div>
                          </Col>
                        </Row>
                      </form>
                    </div>
                  </CardBody>
                </Card>
                <div className="mt-5 text-center">
                       <p className="text-white-50">© {new Date().getFullYear()} BCE PHARMA. all rights reserved.</p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>

    </React.Fragment>
  );
}

export default RecoverPassword;