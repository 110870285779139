// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.row-form {
    background: #fafafa;
    padding: 1%;
    border-radius: 7px;
    box-shadow: 1px 1px 5px #1f51855c;
}
.logo-organization{
    width: 113px;
    height: 54px;
}
label,p, span,a, h5,h6 {
    color:#77797b;
    font-weight: 400;
}
.card-header {
    color: white !important;
    background: #2a77a8c7 !important;
}
#row-concentration{
margin-top: 8px;
}
.table th {
    color: white !important;
    background: rgb(29, 50, 79) !important;
}
.form-control:disabled {
    background-color: #b4cde145!important;
    opacity: 1;
}
.modal-backdrop {

    --bs-backdrop-bg: #0f212d30 !important;
    --bs-backdrop-opacity: 0.5;

}
.card-bordred{
    box-shadow: 2px 2px 4px 1px rgb(5 77 129 / 76%) !important;
}
.fixed-bottom-buttons {
    position: fixed !important;
    bottom: 0;
    right: 0;
    width: 100%;
    background-color: #fff; /* Pour éviter que les boutons ne se chevauchent avec le contenu sous-jacent */
    z-index: 1000;
    box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1); /* Optionnel: Ajout d'une ombre pour distinguer la barre du reste du contenu */
    padding: 10px;
    text-align: right;
  }
  .p-card{
    padding: 1%;
    border: 1px solid #aeb1b3;
  }
  
  `, "",{"version":3,"sources":["webpack://./src/Pages/Formula/style.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,WAAW;IACX,kBAAkB;IAClB,iCAAiC;AACrC;AACA;IACI,YAAY;IACZ,YAAY;AAChB;AACA;IACI,aAAa;IACb,gBAAgB;AACpB;AACA;IACI,uBAAuB;IACvB,gCAAgC;AACpC;AACA;AACA,eAAe;AACf;AACA;IACI,uBAAuB;IACvB,sCAAsC;AAC1C;AACA;IACI,qCAAqC;IACrC,UAAU;AACd;AACA;;IAEI,sCAAsC;IACtC,0BAA0B;;AAE9B;AACA;IACI,0DAA0D;AAC9D;AACA;IACI,0BAA0B;IAC1B,SAAS;IACT,QAAQ;IACR,WAAW;IACX,sBAAsB,EAAE,8EAA8E;IACtG,aAAa;IACb,yCAAyC,EAAE,8EAA8E;IACzH,aAAa;IACb,iBAAiB;EACnB;EACA;IACE,WAAW;IACX,yBAAyB;EAC3B","sourcesContent":[".row-form {\n    background: #fafafa;\n    padding: 1%;\n    border-radius: 7px;\n    box-shadow: 1px 1px 5px #1f51855c;\n}\n.logo-organization{\n    width: 113px;\n    height: 54px;\n}\nlabel,p, span,a, h5,h6 {\n    color:#77797b;\n    font-weight: 400;\n}\n.card-header {\n    color: white !important;\n    background: #2a77a8c7 !important;\n}\n#row-concentration{\nmargin-top: 8px;\n}\n.table th {\n    color: white !important;\n    background: rgb(29, 50, 79) !important;\n}\n.form-control:disabled {\n    background-color: #b4cde145!important;\n    opacity: 1;\n}\n.modal-backdrop {\n\n    --bs-backdrop-bg: #0f212d30 !important;\n    --bs-backdrop-opacity: 0.5;\n\n}\n.card-bordred{\n    box-shadow: 2px 2px 4px 1px rgb(5 77 129 / 76%) !important;\n}\n.fixed-bottom-buttons {\n    position: fixed !important;\n    bottom: 0;\n    right: 0;\n    width: 100%;\n    background-color: #fff; /* Pour éviter que les boutons ne se chevauchent avec le contenu sous-jacent */\n    z-index: 1000;\n    box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1); /* Optionnel: Ajout d'une ombre pour distinguer la barre du reste du contenu */\n    padding: 10px;\n    text-align: right;\n  }\n  .p-card{\n    padding: 1%;\n    border: 1px solid #aeb1b3;\n  }\n  \n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
