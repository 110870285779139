// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rdt_TableHeadRow {
    background-color: #3c9ce2 !important; /* Couleur de fond, changez-la selon vos besoins */
    font-weight: bold; /* Optionnel, si vous voulez renforcer le texte */
}
.font-weight-bold{
    color: white !important;
}
.sc-eEPDDI{
    box-shadow: 1px 1px 5px #1f5185;
    border-radius: 3px !important;
}
.shadow-bloc{
    box-shadow: 1px 1px 5px #1f5185;
}`, "",{"version":3,"sources":["webpack://./src/Pages/Administration/table.css"],"names":[],"mappings":"AAAA;IACI,oCAAoC,EAAE,kDAAkD;IACxF,iBAAiB,EAAE,iDAAiD;AACxE;AACA;IACI,uBAAuB;AAC3B;AACA;IACI,+BAA+B;IAC/B,6BAA6B;AACjC;AACA;IACI,+BAA+B;AACnC","sourcesContent":[".rdt_TableHeadRow {\n    background-color: #3c9ce2 !important; /* Couleur de fond, changez-la selon vos besoins */\n    font-weight: bold; /* Optionnel, si vous voulez renforcer le texte */\n}\n.font-weight-bold{\n    color: white !important;\n}\n.sc-eEPDDI{\n    box-shadow: 1px 1px 5px #1f5185;\n    border-radius: 3px !important;\n}\n.shadow-bloc{\n    box-shadow: 1px 1px 5px #1f5185;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
