import PropTypes from "prop-types";
import React, { useEffect } from "react";
import logolight from "../../assets/images/logo-light.png";
import logodark from "../../assets/images/logo-dark.png";
import { Row, Col, CardBody, Card, Alert, Container, Form, Input, FormFeedback} from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import withRouter from "../../components/Common/withRouter";
import * as Yup from "yup";
import { useFormik } from "formik";
import { loginUser } from "../../store/actions";

const Login = (props) => {
  document.title = "Login | Quess Magistrale";

  const dispatch = useDispatch();

  const validation = useFormik({
  
    enableReinitialize: true,
    initialValues: {
      username: "",
      password: "",
    },
    validationSchema: Yup.object({
      username: Yup.string().required("Please Enter Your Username"),
      password: Yup.string().required("Please Enter Your Password"),
    }),
    onSubmit: (values) => {
      console.log('start log');
      dispatch(loginUser(values, props.router.navigate));
    }
  });

  const loginpage = (state) => ({
    error: state.error,
  });

  const { error } = useSelector(loginpage);

  useEffect(() => {
    document.body.className = "bg-pattern";
    return () => {
      document.body.className = "";
    };
  }, []);

  return (
    <React.Fragment>
      <div className="bg-overlay"></div>
      <div className="account-pages my-5">
        <Container>
          <Row className="justify-content-center">
            <Col lg={8} md={8} xl={6}>
              <Card>
                <CardBody className="p-6">
                  <div>
                    <div className="text-center">
                      <Link to="/">
                        <img
                          src={logodark}
                          alt=""
                          height="24"
                          className="auth-logo logo-dark mx-auto"
                        />
                        <img
                          src={logolight}
                          alt=""
                          height="24"
                          className="auth-logo logo-light mx-auto"
                        />
                      </Link>
                    </div>
                    <br />
                    <section className="jss109">
                      <hr className="jss150" data-content="Sign in with username" style={{ margin: "19px 4px 10px" }} />
                    </section>
                    <Form
                      className="form-horizontal"
                      onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                      }}
                    >
                      {error ? <Alert color="danger"><div>{error}</div></Alert> : null}
                      <Row>
                        <Col md={12}>
                          <div className="mb-4">
                            <Input
                              name="username"
                              className="form-control input"
                              placeholder="Username"
                              type="text"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.username || ""}
                              invalid={
                                validation.touched.username && validation.errors.username ? true : false
                              }
                            />
                            {validation.touched.username && validation.errors.username ? (
                              <FormFeedback type="invalid"><div>{validation.errors.username}</div></FormFeedback>
                            ) : null}
                          </div>
                          <div className="mb-4">
                            <Input
                              name="password"
                              className="form-control input"
                              value={validation.values.password || ""}
                              type="password"
                              placeholder="Password"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              invalid={
                                validation.touched.password && validation.errors.password ? true : false
                              }
                            />
                            {validation.touched.password && validation.errors.password ? (
                              <FormFeedback type="invalid"><div>{validation.errors.password}</div></FormFeedback>
                            ) : null}
                          </div>
                          <Row className="text-center">
                            <Col lg={4} md={4} xs={12} className="text-start">
                              <div className="form-check text-start" style={{ marginLeft: '-20px' }}>
                                <button
                                  className="btn btn-primary waves-effect waves-light"
                                  type="submit"
                                >
                                  Log In <i className="mdi mdi-arrow-right" />
                                </button>
                              </div>
                            </Col>
                            <Col lg={8} md={8} xs={12} className="text-end">
                              <div className="text-md-end mt-3 mt-md-0">
                                <Link to="/forgot-password"
                                  className="btn btn-primary waves-effect waves-light"
                                  type="submit"
                                >
                                  Forgot your password?
                                </Link>
                              </div>
                            </Col>
                          </Row>
                          <div className="text-end mt-4">
                            <h5 className="font-size-14">FRENCH/ENGLISH <i className="bx bx-globe" style={{ fontSize: '18px' }} /></h5>
                          </div>
                        </Col>
                      </Row>
                    </Form>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p className="text-white-50">© {new Date().getFullYear()} BCE PHARMA. all rights reserved.</p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(Login);

Login.propTypes = {
  history: PropTypes.object,
};
