import React from "react";
import { Navigate } from "react-router-dom";

//Dashboard
import Dashboard from "../Pages/Dashboard";

// Import Authentication pages
import Login from "../Pages/Authentication/Login";
import ForgetPasswordPage from "../Pages/Authentication/ForgetPassword";
import Logout from "../Pages/Authentication/Logout";
import Register from "../Pages/Authentication/Register";
import UserProfile from "../Pages/Authentication/user-profile";

// Import Utility Pages
import Error404 from "../Pages/Utility/Error404-Page";
import Error500 from "../Pages/Utility/Error500-Page";

// tables Pages
import ListJs from "../Pages/Tables/ListTables/ListTables";
import DataTable from "../Pages/Tables/DataTables/DataTables";
import ListFormulaTemplate from "../Pages/Template/ListTemplate/List";
import CreateNewTemplate from "../Pages/Template/CreateTemplate";
import ListFormulaSheet from "../Pages/Formula/ListSheet/List";
import CreateNewSheet from "../Pages/Formula/CreateSheet";
import ListIngredients from "../Pages/References/ingredient";
import ListUnits from "../Pages/References/units";
import ListEquipements from "../Pages/References/equipements";
import ListSuppliers from "../Pages/References/supplier";
import ListProducts from "../Pages/References/products";
import ListPharmaceuticals from "../Pages/References/pharmaceutical";
import ListUsers from "../Pages/Administration/ListUsers/List";
import CreateNewUser from "../Pages/Administration/ListUsers/CreateUser";

const authProtectedRoutes = [
  //dashboard
  { path: "/dashboard", component: <Dashboard /> },

  // Profile
  { path: "/userprofile", component: <UserProfile /> },

  // Tables pages
  { path: "/tables-listjs", component: <ListJs /> },
  { path: "/table-datatables", component: <DataTable /> },
  { path: "/List-formula-template", component: <ListFormulaTemplate /> },
  { path: "/create-template", component: <CreateNewTemplate /> },
  { path: "/List-formula-sheet", component: <ListFormulaSheet /> },
  { path: "/create-sheet", component: <CreateNewSheet /> },
  { path: "/ingredient-management", component: <ListIngredients/> },
  { path: "/unit-management", component: <ListUnits/> },
  { path: "/equipement-management", component: <ListEquipements/> },
  { path: "/product-management", component: <ListProducts/> },
  { path: "/pharmaceutical-management", component: <ListPharmaceuticals/> },
  { path: "/supplier-management", component: <ListSuppliers/> },
  { path: "/users-management", component: <ListUsers/> },
  { path: "/create-user", component: <CreateNewUser/> },
  // eslint-disable-next-line react/display-name
  {path: "/",exact: true, component: <Navigate to="/dashboard" /> },
];

const publicRoutes = [
  
  // Authentication Page
  { path: "/logout", component: <Logout /> },
  { path: "/login", component: <Login /> },
  { path: "/forgot-password", component: <ForgetPasswordPage /> },
  { path: "/register", component: <Register /> },

  // Utility Pages
  { path: "/pages-404", component: <Error404 /> },
  { path: "/pages-500", component: <Error500 /> },

];

export { authProtectedRoutes, publicRoutes };
