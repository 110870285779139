import React, { useState ,useEffect} from "react";
import { Card, CardBody, Form, Col, Row,CardSubtitle,Alert, Container, Progress } from "reactstrap";
//import './style.css';
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { useNavigate } from 'react-router-dom';
import Dropzone from "react-dropzone"
import axios from "axios";
import { BACKEND_URL } from "../../../config/const.js";
const CreateNewUser= () => {
  document.title = "Create New User | Quess Magistrale";
  const [selectedFiles, setselectedFiles] = useState([]);
  const [selectedGroups, setSelectedGroups] = useState([]); // Store the selected listOfGroups
  const [listOfGroup, setListOfGroups] = useState(0); // Store the currently selected listOfGroup
  const [organizations, setOrganization] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [passwordStrength, setPasswordStrength] = useState({
    value: 0,
    text: "",
    color: "",
  });
  const [passwordMatch, setPasswordMatch] = useState(true);
  const [formData, setFormData] = useState({
    id:"",
    username: "",
    lastname: "",
    firstname: "",
    password: "",
    email: "",
    phone: "",
    numberPost: "",
    isEnabled: true,
    isAvailable: true,
    has2Fa:true,
    reason: "On vacation",
    other: "Some additional info",
    passwordChange: true,
    listOfGroups:   [1, 23],
    signature: null,
    listRoleOfGroup: [
      {
        "groupId": 1,
        "itemId": 24
      }],
      filesNames: "file1.pdf", // Attribut supplémentaire pour les fichiers
      language: "en",
      currentOrg: 1 // for file uploads
  });
  useEffect(() => {
    const fetchOrganization = async () => {
      try {
        const response = await axios.get(`${BACKEND_URL}api/user/organisations`, config);
        // Assuming response.data is an array of organizations
        const options = response.map((org) => ({
          name: org.name,
          id: org.id,
        }));
        setOrganization(options);
        setLoading(false);
      } catch (error) {
        setError("Failed to load organizations");
        setLoading(false);
      }
    };

    fetchOrganization();
  }, []);
  const config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  };
  const addGroup = () => {
    const selectedGroup = organizations.find((g) => g.id === parseInt(listOfGroup));
    if (selectedGroup && !selectedGroups.find((g) => g.id === selectedGroup.id)) {
      setSelectedGroups([...selectedGroups, selectedGroup]);
      setFormData({ ...formData, listOfGroups: [...formData.listOfGroups, selectedGroup.id] });
    }
  };

  const removeGroup = (listOfGroupId) => {
    setSelectedGroups(selectedGroups.filter((g) => g.id !== listOfGroupId));
  };
  function handleAcceptedFiles(files) {
    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    )
    setselectedFiles(files)
  }

  /**
   * Formats the size
   */
  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }
  const navigate = useNavigate();


  const calculatePasswordStrength = (password) => {
    let strength = 0;
    let text = "Very Weak";
    let color = "dark";
  
    // Check length
    const isLongEnough = password.length >= 4;
    
    if (!isLongEnough) {
      // If password is too short
      setPasswordStrength({ value: 0, text: "Very Weak", color: "dark" });
      return;
    }
  
    // Criteria checks
    const hasUpperCase = /[A-Z]/.test(password);
    const hasLowerCase = /[a-z]/.test(password);
    const hasDigit = /\d/.test(password);
    const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);
  
    const criteriaCount = [hasUpperCase, hasLowerCase, hasDigit, hasSpecialChar].filter(Boolean).length;
  
    switch (criteriaCount) {
      case 4:
        strength = 100;
        text = "Strong";
        color = "success";
        break;
      case 3:
        strength = 75;
        text = "Good";
        color = "info";
        break;
      case 2:
        strength = 50;
        text = "Fair";
        color = "warning";
        break;
      case 1:
        strength = 25;
        text = "Weak";
        color = "danger";
        break;
      default:
        strength = 0;
        text = "Very Weak";
        color = "light";
    }
  
    setPasswordStrength({ value: strength, text, color });
  };
  useEffect(() => {
    calculatePasswordStrength(formData.password);
    checkPasswordMatch(formData.password, formData.passwordConfirmation);
  }, [formData.password, formData.passwordConfirmation]);



  const handlePasswordConfirmationChange = (e) => {
    setFormData({ ...formData, passwordConfirmation: e.target.value });
  };
  const handlePasswordChange = (e) => {
    const password = e.target.value;
    setFormData({ ...formData, password });
    calculatePasswordStrength(password);
  };
  
  const handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();

    const userData = {
    id: formData.id,
    username: formData.username,
    lastname: formData.lastname,
    firstname: formData.firstname,
    password: formData.password,
    passwordConfirmation: formData.passwordConfirmation,
    email: formData.email,
    phone: formData.phone,
    numberPost: formData.numberPost,
    isEnabled: formData.isEnabled,
    isAvailable: formData.isAvailable,
    has2Fa: formData.has2Fa,
    reason: formData.reason,
    other: formData.other,
    passwordChange: formData.passwordChange,
    listOfGroup: formData.listOfGroups, // Pas besoin de JSON.stringify
    listRoleOfGroup: formData.listRoleOfGroup, // Pas besoin de JSON.stringify
    filesNames: formData.filesNames,
    language: formData.language,
    currentOrg: formData.currentOrg,
    signature: formData.signature ? formData.signature : null // Gérer les fichiers si besoin
};

    try {
      const response = await axios.post(`${BACKEND_URL}api/user/save`, userData, config);
      setLoading(false);
      console.log("User saved successfully:", response.data);
      navigate("/users-management"); // Redirect after successful creation
    } catch (error) {
      setLoading(false);
      console.error("Error saving user:", error);
    }
  };
  const checkPasswordMatch = (password, passwordConfirmation) => {
    setPasswordMatch(password === passwordConfirmation);
  };
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="User" breadcrumbItem="Create New User" />
          {loading ? (
            <Alert color="info">Loading subbmission...</Alert>
          ) : error ? (
            <Alert color="danger">{error}</Alert>
          ) : null}
          <div className=" mb-4">
            <Form onSubmit={handleSubmit}>
        
            <Row>
              <Col lg={12}>
                <Card className="card-bordred">
                  <h5 className="card-header">User Information</h5>
                  <CardBody>
                    <Row className="mt-3">
                      <Col lg={4}>
                        <label className="form-label">User Name</label>
                        <input name="username" className="form-control" type="text"
                          placeholder="username"   value={formData.username}
                          onChange={(e) => setFormData({ ...formData, username: e.target.value })}
                        />
                      </Col>
                      <Col lg={4}>
                        <label className="form-label">Last Name</label>
                        <input
                          name="lastname"className="form-control"
                          type="text"placeholder="Last Name" value={formData.lastname}
                          onChange={(e) => setFormData({ ...formData, lastname: e.target.value })}
                        />
                      </Col>
                      <Col lg={4}>
                        <label className="form-label">First Name</label>
                        <input name="firstname"
                          className="form-control" type="text"
                          placeholder="First Name" value={formData.firstname}
                          onChange={(e) => setFormData({ ...formData, firstname: e.target.value })}
                        />
                      </Col>
                    </Row>
                    <Row className="mt-3">
                    <Col lg={4}>
                        <label className="form-label">Password</label>
                        <input
                          name="password"
                          className="form-control"
                          type="password"
                          value={formData.password}
                          onChange={handlePasswordChange}
                        />
                      </Col>
                      <Col lg={4}>
                        <label className="form-label">Password Confirmation</label>
                        <input
                          name="passwordConfirmation"
                          className="form-control"
                          type="password"
                          value={formData.passwordConfirmation}
                          onChange={handlePasswordConfirmationChange}
                        />
                           {formData.passwordConfirmation && !passwordMatch && (
                              <code className="text-danger">Passwords do not match</code>
                            )}
                      </Col>
                      <Col lg={4}>
                        <div className={`progress-label text-${passwordStrength.color} border-${passwordStrength.color} mb-2`}>
                          {passwordStrength.text}
                        </div>
                        <Progress striped color={passwordStrength.color} value={passwordStrength.value} id="strongpassword" />
                      </Col>
                    </Row>

                    <Row className="mt-3">
                      <Col lg={4}>
                        <label className="form-label">Email</label>
                        <input
                          name="email"
                          className="form-control"
                          type="email"
                          placeholder="Email"
                          value={formData.email}
                          onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                        />
                      </Col>
                      <Col lg={4}>
                        <label className="form-label">Phone</label>
                        <input
                          name="phone"
                          className="form-control"
                          type="text"
                          placeholder="050310420215"
                          value={formData.phone}
                          onChange={(e) => setFormData({ ...formData, phone: e.target.value })}
                        />
                      </Col>
                      <Col lg={4}>
                        <label className="form-label">Number Post</label>
                        <input
                          name="numberPost"
                          className="form-control"
                          type="text"
                          placeholder="0001"
                          value={formData.numberPost}
                          onChange={(e) => setFormData({ ...formData, numberPost: e.target.value })}
                        />
                      </Col>
                    </Row>

                    <Row className="mt-3">
                      <Col lg={4}>
                        <div className="form-check">
                          <input
                            name="isEnabled"
                            className="form-check-input"
                            type="checkbox"
                            checked={formData.isEnabled}
                            onChange={(e) => setFormData({ ...formData, isEnabled: e.target.checked })}
                          />
                          <label className="form-check-label">Activate/Deactivate Access</label>
                        </div>
                      </Col>
                      <Col lg={4}>
                        <select className="form-select" disabled>
                          <option>Creation</option>
                          <option>Enabled</option>
                          <option>Disabled</option>
                        </select>
                      </Col>
                      <Col lg={4}>
                        <div className="form-check">
                          <input
                            name="passwordChange"
                            className="form-check-input"
                            type="checkbox"
                            checked={formData.passwordChange}
                            onChange={(e) => setFormData({ ...formData, passwordChange: e.target.checked })}
                          />
                          <label className="form-check-label">Change password on login</label>
                        </div>
                      </Col>
                    </Row>

                    {/* Signature Upload */}
                    <Row>
                      <Col className="col-12">
                        <Card>
                          <CardBody>
                            <CardSubtitle>Signature:</CardSubtitle>
                            <Form className="dropzone">
                              <Dropzone
                                onDrop={acceptedFiles => handleAcceptedFiles(acceptedFiles)}
                              >
                                {({ getRootProps, getInputProps }) => (
                                  <div className="dropzone">
                                     <div style={{textAlign:"center"}}>
                                    <div
                                      className="dz-message needsclick"
                                      {...getRootProps()}
                                    >
                                      <input {...getInputProps()} />
                                      <div className="mb-3 ">
                                        <i className="display-4 text-muted ri-upload-cloud-2-line"></i>
                                      </div>
                                      <h4>Drop files here or click to upload.</h4>
                                    </div>
                                  </div>
                                  </div>
                                )}
                              </Dropzone>
                            </Form>
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>

          <Row className="mt-3">
        <Col lg={3}>
          <div className="form-check form-check-end">
            {listOfGroup === 0 ? (
              <label className="form-check-label text-danger">Add A Group</label>
            ) : (
              <label className="form-check-label text-info">Add Another Group</label>
            )}
          </div>
        </Col>
        <Col lg={6}>
          <select
            className="form-select"
            name="listOfGroup"
            onChange={(e) => setListOfGroups(e.target.value)} 
          >
            <option aria-selected="true" role="option" value="0" disabled>
              Select Group
            </option>
            {organizations.map((listOfGroup) => (
              <option key={listOfGroup.id} value={listOfGroup.id}>
                {listOfGroup.name}
              </option>
            ))}
          </select>
        </Col>
        <Col lg={3}>
          <div className="form-check text-center">
            <button
              className="btn btn-outline-info waves-effect waves-light" style={{borderRadius:'50%'}}
              type="button"
              onClick={addGroup}
            >
              <i className="fa fa-plus" />
            </button>
          </div>
        </Col>
      </Row>

      {selectedGroups.map((selectedGroup) => (
        <Row className="mt-3 row-form" id={`listOfGroupId-${selectedGroup.id}`} key={selectedGroup.id}>
          <Col lg={3}>
            <div className="form-check form-check-end">
              <label className="form-check-label text-succes">{selectedGroup.name}</label>
            </div>
          </Col>
          <Col lg={6}>
            <h5 className="font-size-14 mb-3">
              <u>Quess Magistrale</u>
            </h5>
            <div className="form-check form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          defaultValue=""
                          id="inlineCheck1"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="inlineCheck1" 
                        >
                          Access to the dashboard
                        </label>
                      </div>
                      <div className="form-check form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          defaultValue=""
                          name="itemId"
                          id="inlineCheck1"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="inlineCheck1"
                        >
                          
                        Allow PDF download
                        </label>
                      </div>
                      <div className="form-check form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          defaultValue=""
                          id="inlineCheck2"
                          defaultChecked
                        />
                        <label
                          className="form-check-label"
                          htmlFor="inlineCheck2"
                        >
                  Super Administrator
                        </label>
                      </div>
                      <div className="form-check form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          defaultValue=""
                          id="inlineCheck1"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="inlineCheck1"
                        >
                          
                          Allow PDF upload
                        </label>
                      </div>
                      <div className="form-check form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          defaultValue=""
                          id="inlineCheck2"
                          defaultChecked
                        />
                        <label
                          className="form-check-label"
                          htmlFor="inlineCheck2"
                        >
                        All logs access
                        </label>
                      </div>
                      <div className="form-check form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          defaultValue=""
                          id="inlineCheck1"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="inlineCheck1"
                        >
                          
                        
                      Access to the reports
                        </label>
                      </div>

            {/* Add other checkboxes as needed */}
          </Col>
          <Col lg={3}>
          <div className="form-check text-center">
            <button
              className="btn btn-outline-danger" style={{borderRadius:'50%'}}
              type="button"
              onClick={() => removeGroup(selectedGroup.id)}
            >
              <i className="fa fa-minus" />
            </button>
            </div>
          </Col>
        </Row>
      ))}
                    </CardBody>
                  </Card>
                </Col>



                <Col lg={12} className="fixed-bottom-buttons">
                  <div className="float-end">
                    <button type="submit" className="ms-1 btn btn-success me-3">Save</button>
                    <button type="reset" className="btn btn-info">Cancel</button>
                  </div>
                </Col>

              </Row>
            </Form>
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default CreateNewUser;
