import React, { useState } from "react";
import { Card, CardBody, Form, Col, Row, Container, CardText, CardTitle, Input, Label } from "reactstrap";
import logobce from "../../assets/images/logo_bce.png";
import logoorganization from "../../assets/images/organization_logo.png";
import './style.css';
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
const Loader = () => {
  return (
    <div className="loader-container text-center">
      <div className="spinner-border text-primary" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
    </div>
  );
};
const CreateNewSheet = () => {
  document.title = "Create New Sheet | Quess Magistrale";
  const [displayNone, setDisplayNone] = useState(false);
  const [loading, setLoading] = useState(false);
  const [formulaBatch, setFormulaBatch] = useState('');
  const [desiredBatch, setDesiredBatch] = useState('');
  const [unitFormula, setUnitFormula] = useState('Units');
  const [unitDesired, setUnitDesired] = useState('Units');
  const handleDisplay = () => {
    setLoading(true); // Show the loader

    setTimeout(() => {
      setLoading(false); // Hide the loader
      setDisplayNone(true); // Show the form after 3 seconds
    }, 3000);
  };

  const handleFormulaBatchChange = (e) => {
    setFormulaBatch(e.target.value);
    setDesiredBatch(e.target.value); // Update desiredBatch in real-time
  };

  const handleDesiredBatchChange = (e) => {
    setDesiredBatch(e.target.value);
    setFormulaBatch(e.target.value); // Update formulaBatch in real-time
  };

  const handleUnitFormulaChange = (e) => {
    setUnitFormula(e.target.value);
    setUnitDesired(e.target.value); // Update unitDesired in real-time
  };

  const handleUnitDesiredChange = (e) => {
    setUnitDesired(e.target.value);
    setUnitFormula(e.target.value); // Update unitFormula in real-time
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    // Add your form submission logic here
    console.log("Form submitted");
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="Preparation Sheet" breadcrumbItem="Select New Preparation" />

          <div className="row-form mb-4">
            <Row className="mb-3 mt-2">
              <Col md={4} className="pe-4">
                <select className="form-select">
                  <option>Select category</option>
                  <option>Sheet Sterile</option>
                  <option>Sheet Non Sterile</option>
                </select>
              </Col>
              <Col md={4} className="pe-4" >
                <select className="form-select">
                  <option>Select Template</option>
                  <option>TemplateName 1</option>
                  <option>TemplateName 2</option>
                </select>
              </Col>
              <Col md={3} >
                <button className="ms-1 btn btn-info me-3" style={{ width: "100%" }} onClick={handleDisplay}>
                  Generate <i className=" bx bxs-file-txt me-1"></i>
                </button>
              </Col>
            </Row>
            {loading ? (
              <Loader />
            ) : displayNone ? (

              <Form onSubmit={handleSubmit}>

                <Row>
                  <Col lg={12}>
                    <Card className="card-bordred">
                      <h5 className="card-header">Header</h5>
                      <div className="text-center"> </div>
                      <CardBody>
                        <Row className="mb-3 mt-2">
                          <Col md={2}>
                            <img
                              src={logobce}
                              alt=""
                              height="24"
                              className="auth-logo logo-dark mx-auto"
                            />
                          </Col>
                          <Col md={8} className="text-center">

                            <Label className="col-form-label">Compounded Sterile Preparation Protocol</Label>
                            <p> <span className="">Number : 123456789  </span> <span className=""> Version: 1</span></p>

                            <div className="col-md-12 text-center mb-2">
                              <p className="p-card">
                                <span>Name of Compounded product 1 concentration 1 symbole 1</span><br />
                                <span>Name of Compounded product 2 concentration 2 symbole 2</span>
                              </p>
                              <br />
                              <span className=" bg-success p-3 text-white">STATUS: Creation </span>
                              <br /><br />
                            </div>


                          </Col>
                          <Col md={2}>
                            <img
                              src={logoorganization}
                              alt=""
                              height="24"
                              className="logo-organization"
                            />
                          </Col>
                        </Row>

                        <Row  >
                          <Col lg={3}>
                            <div>
                              <Label htmlFor="route_of-administration " className=" col-form-label ms-3"  > Route of administration: </Label>
                            </div>
                          </Col>
                          <Col lg={3}>
                            <div>
                              < Input className="form-control" type="text" placeholder="Route of administration" disabled />
                            </div>
                          </Col>
                          <Col lg={3}>
                            <div>
                              <Label htmlFor="effective_date " className="col-form-label ms-3"  > Effective Date: </Label>
                            </div>
                          </Col>
                          <Col lg={3}>
                            <div>
                              <Input className="form-control" type="date" defaultValue="2024-08-09" id=" " disabled />
                            </div>
                          </Col>
                        </Row>

                        <Row className="mt-2" >
                          <Col lg={3}>
                            <div>
                              <Label htmlFor="pharmaceutical_form" className=" col-form-label ms-3"  > Pharmaceutical form: </Label>
                            </div>
                          </Col>
                          <Col lg={3}>
                            <div>
                              < Input className="form-control" type="text" placeholder="Pharmaceutical 1" disabled />
                            </div>
                          </Col>
                          <Col lg={3}>
                            <div>
                              <Label htmlFor="authorized_by" className="col-form-label ms-3"  > Authorized by: </Label>
                            </div>
                          </Col>
                          <Col lg={3}>
                            <div>
                              < Input className="form-control" type="text" placeholder="User 1 / User 2" disabled />
                            </div>
                          </Col>

                        </Row>

                        <Row className="mt-3">
                          <Col lg={3}>
                            <div>
                              <Label htmlFor="prepared_by" className="col-form-label ms-3"  > Prepared by: </Label>
                            </div>
                          </Col>
                          <Col lg={3}>
                            <div>
                              <select className="form-select">
                                <option>User 1</option>
                                <option>User 2</option>
                                <option>User 3</option>
                              </select>
                            </div>
                          </Col>
                          <Col lg={3}>
                            <div>
                              <Label htmlFor="checked_by" className="col-form-label ms-3"  > Checked by: </Label>
                            </div>
                          </Col>
                          <Col lg={3}>
                            <div>
                              <select className="form-select">
                                <option>User 1</option>
                                <option>User 2</option>
                                <option>User 3</option>
                              </select>
                            </div>
                          </Col>

                        </Row >


                      </CardBody>
                    </Card>
                  </Col>

                  <Col lg={12}>
                    <Card className="card-bordred">
                      <h5 className="card-header">Formula </h5>
                      <CardBody>



                        <Row className="mt-3">
                          <Col lg={3}>
                            <div className="row">
                              <label htmlFor="formula_batch" className="col-md-8 col-form-label ">Formula Batch Size:</label>
                              <div className="col-md-4">
                                <input
                                  id="formula_batch"
                                  className="form-control"
                                  type="text"
                                  value={formulaBatch}
                                  placeholder="200"
                                  onChange={handleFormulaBatchChange}
                                  name="formula_batch" disabled
                                />
                              </div>
                            </div>
                          </Col>

                          <Col lg={2}>
                            <div>
                              <select
                                className="form-select"
                                name="unit_formula"
                                value={unitFormula}
                                onChange={handleUnitFormulaChange}
                                disabled
                              >
                                <option value="Units">Units</option>
                                <option value="vials">vials</option>
                                <option value="mls">mls</option>
                                <option value="bags">bags</option>
                              </select>
                            </div>
                          </Col>

                          <Col lg={2}></Col>

                          <Col lg={3}>
                            <div className="row">
                              <label htmlFor="desired_batch" className="col-md-8 col-form-label ">Desired Batch Size:</label>
                              <div className="col-md-4">
                                <input
                                  id="desired_batch"
                                  className="form-control"
                                  type="text"
                                  value={desiredBatch}
                                  placeholder="200"
                                  onChange={handleDesiredBatchChange}
                                  name="desired_batch"
                                />
                              </div>
                            </div>
                          </Col>

                          <Col lg={2}>
                            <div>
                              <select
                                className="form-select"
                                name="unit_desired"
                                value={unitDesired}
                                onChange={handleUnitDesiredChange}
                              >
                                <option value="Units">Units</option>
                                <option value="vials">vials</option>
                                <option value="mls">mls</option>
                                <option value="bags">bags</option>
                              </select>
                            </div>
                          </Col>
                        </Row>
                        <Row className="mt-3">
                          <div className="table-responsive">
                            <table className="table table-bordered mb-0">
                              <thead style={{ background: "black" }}>
                                <tr id="formula">
                                  <th>Ingredients</th>
                                  <th>Manufacture</th>
                                  <th>Lot Number</th>
                                  <th>Exp Date</th>
                                  <th>Quantity</th>
                                  <th>Units</th>
                                  <th>Quantity Required</th>

                                </tr>
                              </thead>
                              <tbody>

                                <tr>
                                  <td>  < Input className="form-control" type="text" defaultValue="Cefazon 10g vials" disabled /> </td>
                                  <td> < Input className="form-control" type="text" defaultValue="Steri" disabled />  </td>
                                  <td> < Input className="form-control" type="text" placeholder="CEF123" /></td>
                                  <td> < Input className="form-control" type="date" placeholder="2024-08-10" id=" " /></td>
                                  <td> < Input className="form-control" type="text" defaultValue="10" disabled /></td>
                                  <td> < Input className="form-control" type="text" defaultValue="vials" disabled /></td>
                                  <td> < Input className="form-control" type="text" disabled /></td>

                                </tr>
                                <tr>
                                  <td> < Input className="form-control" type="text" defaultValue="D5W 50mL bag" disabled /></td>
                                  <td>< Input className="form-control" type="text" defaultValue="bax" disabled /> </td>
                                  <td> < Input className="form-control" type="text" placeholder="H20456" /></td>
                                  <td> < Input className="form-control" type="date" placeholder="2024-08-10" id=" " /></td>
                                  <td> < Input className="form-control" type="text" defaultValue="100" disabled /></td>
                                  <td> < Input className="form-control" type="text" defaultValue="mls" disabled /></td>
                                  <td> < Input className="form-control" type="text" disabled /></td>

                                </tr>


                              </tbody>
                            </table>
                          </div>
                        </Row>

                      </CardBody>
                    </Card>
                  </Col>

                  <Col lg={12}>
                    <Card className="card-bordred">
                      <h5 className="card-header">Required equipement/materiels </h5>
                      <CardBody>

                        <Row className="mt-3">
                          <div className="table-responsive">
                            <table className="table table-bordered mb-0">
                              <thead style={{ background: "black" }}>
                                <tr id="Equipement">

                                  <th>Equipement/materials</th>
                                  <th>Quantity</th>
                                  <th>Other</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr >

                                  <td>  < Input className="form-control" type="text" defaultValue="20 mL Syringe" disabled /></td>
                                  <td>  < Input className="form-control" type="number" defaultValue="120" disabled />  </td>
                                  <td>  < Input className="form-control" type="text" defaultValue="test" disabled /></td>
                                </tr>
                                <tr >

                                  <td>  < Input className="form-control" type="text" defaultValue="20 mL Syringe" disabled /></td>
                                  <td> < Input className="form-control" type="number" defaultValue="120" disabled />  </td>
                                  <td>  < Input className="form-control" type="text" defaultValue="test" disabled /></td>
                                </tr>
                                <tr >

                                  <td> < Input className="form-control" type="text" defaultValue="20 mL Syringe" disabled /></td>
                                  <td> < Input className="form-control" type="number" defaultValue="120" disabled /></td>
                                  <td>  < Input className="form-control" type="text" defaultValue="test" disabled /></td>
                                </tr>

                              </tbody>
                            </table>
                          </div>
                        </Row>

                      </CardBody>
                    </Card>
                  </Col>


                  <Col lg={12}>
                    <Card className="card-bordred">
                      <h5 className="card-header">Additional information on ingredients</h5>
                      <CardBody>

                        <CardText>
                          Include any additional pertinent information about the ingredients required for compounding.<br />
                          Indicate any specific precautions to be taken when handling the ingredients.<br /><br />
                          <img className="text-end" src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQZ_VH8kCHmyjj6LprJVamvFQUxn2BvtxXqDNsHdU4KeSsPp8jlwGRySNJOf1_5u9LN5WQ&usqp=CAU" alt="" />
                        </CardText>

                      </CardBody>
                    </Card>
                    <Card className="card-bordred">
                      <h5 className="card-header">Calculations and Measurements</h5>
                      <CardBody>

                        <CardText>
                          Indicate any characteristics of the calculations, measurements or ingredient preparation that must be done before the specific
                          procedure is carried out.<br />
                          Indicate any requirement for verification by the pharmacist.<br />
                          Examples:<br />
                          - Quality control of devices to be carried out and documented before measurements are taken.<br />
                          - Accuracy of measurement devices. <br />
                          - Verification and documentation of ingredients, batch numbers and beyond-use dates.<br />
                          - Type of report required on the compounding form
                        </CardText>

                      </CardBody>
                    </Card>
                    <Card className="card-bordred">
                      <h5 className="card-header">Compounding Method</h5>
                      <CardBody>

                        <CardText>
                          Describe all steps of the sterile-product compounding process. Below is an example of what this should look like:<br />
                          <br />
                          1. Gather all materials required, disinfect bin and all materials, and place labels and worksheet in plastic sleeve.
                          Place all materials in passthrough. Product must be prepared in an ISO 5 PEC operating in an ISO 7 clean room.<br /><br />
                          2. Don PPE in compliance with policies and procedures, and disinfect gloved hands<br /><br />
                          3. Disinfect work surface of PEC. Wipe all materials into PEC using presaturated 70% sterile alcohol wipes. <br /><br />
                          4. Sanitize H20 vial septum with alcohol swab. Assemble a 20 g needle onto a 20 ml syringe, and withdraw 10 mLs of H20 from H20 vial.
                          Have volume validated by verifier. VC performed by: __________ (digital signature?)<br /><br />
                          5. Assemble a ventilated assembly utilizing a 25 g needle and a 1 mL syringe. Pull the plunger from the 1 mL syringe,
                          and install onto antibiotic Y vial. (Optional - utilize a vial spike on antibiotic Y vial instead of ventilated assembly)<br /><br />
                          6. Sanitize Antibiotic Y vial septum with alcohol swab. Reconstitute antibiotic Y with 10 mLs of H20. Gently swirl vial to mix,
                          and avoid excessive foaming. Inspect vial for coring or other particulate, or precipitation.<br /><br />
                          7. Sanitize Antibiotic Y vial septum with alcohol swab. Attach a new 20 g needle to 20 mL syringe, and withdraw 10 mLs of the
                          reconstituted solution. Have volume validated by verifier.  VC performed by: __________ (digital signature?)<br /><br />
                          8. Determine required volume of medication to inject: ____________ mLs of solution. Using a luer lock connector, transfer the required
                          amount of solution to an appropriate sized syringe.  VC performed by: __________ (digital signature?)<br /><br />
                          9. Sanitize Diluent Z additive port with alchohol swab. Inject ________mLs of solution into Diluent Z bag.
                          Sanitize medication port of bag, and inspect for coring, precipitate, and discolouration. Squeeze bag to ensure no leaking. <br /><br />
                          10. Affix label to diluent Z bag, and place into a protect from light sleeve.
                        </CardText>

                      </CardBody>
                    </Card>
                    <Card className="card-bordred">
                      <h5 className="card-header">Quality Control</h5>
                      <CardBody>

                        <CardText>
                          Specify the procedure for determining the lot number of the final compounded sterile preparation.<br />

                          Specify all quality control procedures that are to be carried out during compounding and documented by the pharmacist and/or
                          pharmacy technician. <br />

                          Specify all quality control procedures that are to be carried out by the pharmacist on the final compounded sterile preparation.

                          Indicate the expected specifications.<br /><br />

                          EXAMPLE:
                          Lot # Determination: date - first 3 letters of drug name (yyyymmdd-xxx):  Lot# Assigned: ______________________ <br />

                          Final product should be clear, yellowish solution with no visible particles<br />

                          QC Check ingredients before compounding:      _________ Compounder initials  _________ Verifier initials<br />

                          QC Volume check of drawn up H2O:                    _________ Compounder initials  _________ Verifier initials<br />

                          QC Volume check of reconstituted solution:      _________ Compounder initials  _________ Verifier initials<br />

                          QC of final product:                                                 _________ Compounder initials  _________ Verifier initials

                        </CardText>

                      </CardBody>
                    </Card>
                    <Card className="card-bordred">
                      <h5 className="card-header">Packaging</h5>
                      <CardBody>

                        <CardText>
                          Describe the type of packaging in which the final compounded sterile preparation will be presented to the patient.
                          <br />  Example:<br />
                          Each bag must be in a protect from light sleeve<br />
                          Each bag must have a refrigerate auxiliary label<br />
                          Each bag must have a tamper proof seal<br />
                        </CardText>

                      </CardBody>
                    </Card>
                    <Card className="card-bordred">
                      <h5 className="card-header">Stability and Storage</h5>
                      <CardBody>

                        <CardText>
                          Specify the preservation requirements of the compounded sterile preparation.<br />
                          Specify the shelf life of the compounded sterile preparation (beyond-use date).<br />
                          Indicate the references used to determine shelf life.<br />
                          Example:<br />
                          BUD: 9 days<br />
                          Storage: Refrigerate<br />
                          References: Antibiotic Y Peer reviewed study on stability in dynamic conditions, 2023<br />
                        </CardText>

                      </CardBody>
                    </Card>
                    <Card className="card-bordred">
                      <h5 className="card-header">Training</h5>
                      <CardBody>

                        <CardText>
                          Indicate the training that personnel must undergo before the specific sterile compounding procedure is implemented
                          Example:<br />
                          Non-Hazardous training and certification
                        </CardText>

                      </CardBody>
                    </Card>
                    <Card className="card-bordred">
                      <h5 className="card-header">References Consulted</h5>
                      <CardBody>

                        <CardText>
                          Indicate the source of the specific sterile compounding procedure.<br />
                          Indicate any documentation supporting the stability of the final compounded sterile preparation.
                          Example:<br />
                          1.NAPRA Model Standards for pharmacy compunding of non-hazardous sterile preparations, 2016<br />
                          2. Antibiotic Y Peer reviewed study on stability in dynamic conditions, 2024<br />
                        </CardText>

                      </CardBody>
                    </Card>
                  </Col>
                  <Col lg={12} className="fixed-bottom-buttons">
                    <div className="float-end">
                      <button type="submit" className="ms-1 btn btn-success me-3">Save</button>
                      <button type="reset" className="btn btn-info">Cancel</button>
                    </div>
                  </Col>
                </Row>
              </Form>
            ) : null}
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default CreateNewSheet;
