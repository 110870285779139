import React from "react";
import Routes from "./Routes/index";
import ErrorBoundary from './ErrorBoundary'; // Import the ErrorBoundary component
// Import Scss
import './assets/scss/theme.scss';

// Fake Backend 
import fakeBackend from "./helpers/AuthType/fakeBackend";

// Activating fake backend
fakeBackend();

function App() {
  return (
    <React.Fragment>
    <ErrorBoundary>
      <Routes />
    </ErrorBoundary>
  </React.Fragment>
  );
}

export default App;
