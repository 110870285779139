import React, { useState, useEffect } from 'react';
import { Button, Card, CardBody, Col, Container, Row, Modal, Input, ModalBody, Alert, ModalFooter, ModalHeader } from 'reactstrap';
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Link } from 'react-router-dom';
import axios from 'axios';
import './style.css';
import { BACKEND_URL, BACKEND_Externe_URL } from "../../config/const.js";
const ListProducts = () => {
    const [modal_list, setmodal_list] = useState(false);
    const [modal_delete, setmodal_delete] = useState(false);
    const [editIndex, setEditIndex] = useState(null);
    const [products, setProducts] = useState([]);
    const [newProduct, setNewProduct] = useState({ nameEn: '', nameFr: '', clientId: null });
    const [editedProduct, setEditedProduct] = useState({ nameEn: '', nameFr: '' });
    const [selectedIds, setSelectedIds] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [clientId, setClientId] = useState(null);
    const [modal_standard, setmodal_standard] = useState(false);
    const [productToDelete, setProductToDelete] = useState(null);
    const [loading, setLoading] = useState(false);
    const [alert, setAlert] = useState(null);
    const [message, setMessage] = useState(null);
    function tog_standard() {
        setmodal_standard(!modal_standard);
    }
    const itemsPerPage = 5;
    const client = JSON.parse(localStorage.getItem("user"));

    console.log('user', client.id);

    useEffect(() => {
        setClientId(client.id);
    }, [clientId]);
    const config = {
        headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
    };
    // Fetch product from API
    useEffect(() => {
        console.log('products:', products);
    }, [products]);
    useEffect(() => {
        fetchProducts();
    }, [clientId]);

    const fetchProducts = async () => {
        try {
            if (clientId) {
                const response = await axios.get(`${BACKEND_URL}api/productTypes/${clientId}`, config);
                console.log('res', response);
                setProducts(response);
            }

        } catch (error) {
            console.error('Error fetching product:', error);
        }
    };

    const handleEditClick = (index) => {
        setEditIndex(index);
        setEditedProduct({ ...products[index] });
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setEditedProduct(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    // Update product
    const handleSave = async (index) => {
        try {
            const updatedProduct = {
                id: editedProduct.id,
                nameEn: editedProduct.nameEn,
                nameFr: editedProduct.nameFr
            };

            await axios.put(`${BACKEND_URL}api/productTypes/update`, updatedProduct, config);

            // Update the state after successful PUT request
            const updatedProducts = [...products];
            updatedProducts[index] = updatedProduct; // Replace the updated product in the array
            setProducts(updatedProducts);
            setEditIndex(null); // Exit edit mode
        } catch (error) {
            console.error('Error updating product:', error);
        }
    };

    const handleCancel = () => {
        setEditIndex(null);
    };

    // Delete product
    const handleRemove = async (id) => {
        try {
            await axios.delete(`${BACKEND_URL}api/productTypes/${id}/delete`, config);
            const updatedProducts = products.filter(product => product.id !== id);
            setProducts(updatedProducts);
            setmodal_standard(false); 
            setMessage('Product removed successfully...');
            setLoading(true);
            setAlert('warning');
            setTimeout(() => {
                setLoading(false);
            }, 3000);// Close the modal after deletion
        } catch (error) {
            console.error('Error deleting product:', error);
        }
    };

    // Add new product
    // Add new product
    const handleAddProduct = async (e) => {
        e.preventDefault();
        try {
            
            // Ensure clientId is set in the newProduct object
            const productToCreate = { ...newProduct, clientId: clientId };

            const response = await axios.post(`${BACKEND_URL}api/productTypes/new`, productToCreate, config);
            setProducts([...products, response.data]);
            fetchProducts();
            setmodal_list(false);
            setMessage('Product added successfully...');
            setLoading(true);
            setAlert('success');
            setTimeout(() => {
                setLoading(false);
            }, 3000);
            
        } catch (error) {
            setLoading(true);
            setAlert('danger');
            setMessage('Error adding new product ');
            console.error('Error adding new product:', error);
            setTimeout(() => {
                setLoading(false);
            }, 2000);
        }
    };


    const handleCheckboxChange = (id) => {
        setSelectedIds(prevSelectedIds =>
            prevSelectedIds.includes(id)
                ? prevSelectedIds.filter(selectedId => selectedId !== id)
                : [...prevSelectedIds, id]
        );
    };

    // const handleDeleteMultiple = async () => {
    //     try {
    //         const deletePromises = selectedIds.map(id => axios.delete(`${BACKEND_URL}api/productTypes/${id}/delete`));
    //         await Promise.all(deletePromises);
    //         const updatedProducts = products.filter(product => !selectedIds.includes(product.id));
    //         setProducts(updatedProducts);
    //         setSelectedIds([]);
    //     } catch (error) {
    //         console.error('Error deleting multiple product:', error);
    //     }
    // };

    // Filter and paginate the product
    // Filter and paginate the product
    const filteredProducts = products && products.filter(product => {
        if (!product) return false; // Ensure product is not null or undefined
        const nameFr = product.nameFr ? product.nameFr.toLowerCase() : '';
        const nameEn = product.nameEn ? product.nameEn.toLowerCase() : '';

        return nameFr.includes(searchTerm.toLowerCase()) || nameEn.includes(searchTerm.toLowerCase());
    });


    const totalItems = filteredProducts.length;
    const totalPages = Math.ceil(totalItems / itemsPerPage);
    const displayedProducts = filteredProducts.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                <Breadcrumbs title="Management" breadcrumbItem="List Compound Product " />

                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardBody>
                                    <div id="customerList">
                                        <Row className="g-4 mb-3">
                                            <Col className="col-sm-auto">
                                                <div className="d-flex gap-1">
                                                    <Button color="success" className="add-btn" onClick={() => setmodal_list(true)} id="create-btn">
                                                        <i className="ri-add-line align-bottom me-1"></i> Add Compound Product
                                                    </Button>
                                                    {/* <button type="button" className="btn btn-outline-danger waves-effect waves-light" onClick={handleDeleteMultiple}>  
                                                           <i className="ri-delete-bin-2-line"></i> Delete Multiple
                                                    </button> */}
                                                </div>
                                            </Col>
                                     
                                          
                                                                                 
                                            <Col className="col-sm">
                                                <div className="d-flex justify-content-sm-end">
                                                    <div className="search-box ms-2" style={{ display: 'flex' }}>
                                                        <i className="ri-search-line search-icon mt-1 p-2"></i>
                                                        <input
                                                            type="text"
                                                            className="form-control search"
                                                            placeholder="Search..."
                                                            value={searchTerm}
                                                            onChange={(e) => setSearchTerm(e.target.value)}
                                                        />

                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>

                                        <div className="table-responsive table-card mt-3 mb-1">
                                                
                                        {loading ? (
                                                 
                                                 <Alert color={alert}>{message}</Alert>
                                  
                                             ) : null}
                                            <table className="table align-middle table-nowrap" id="customerTable">
                                                <thead className="table-light">
                                                    <tr>
                                                        <th scope="col" style={{ width: "50px" }}>
                                                            <div className="form-check">
                                                                <input
                                                                    className="form-check-input"
                                                                    type="checkbox"
                                                                    id="checkAll"
                                                                    value="option"
                                                                    onChange={(e) => {
                                                                        const isChecked = e.target.checked;
                                                                        setSelectedIds(isChecked ? products.map(i => i.id) : []);
                                                                    }}
                                                                    checked={selectedIds.length === products.length && products.length > 0}
                                                                />
                                                            </div>
                                                        </th>
                                                        <th className="sort" data-sort="id">#ID</th>
                                                        <th className="sort" data-sort="nameFr">Name_Fr</th>
                                                        <th className="sort" data-sort="nameEn">Name_En</th>
                                                        <th className="sort" data-sort="action">Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody className="list form-check-all">
                                                    {displayedProducts && displayedProducts.map((product, index) => (
                                                        <tr key={product.id}>
                                                            <th scope="row">
                                                                <div className="form-check">
                                                                    <input
                                                                        className="form-check-input"
                                                                        type="checkbox"
                                                                        name="chk_child"
                                                                        value="option1"
                                                                        onChange={() => handleCheckboxChange(product.id)}
                                                                        checked={selectedIds.includes(product.id)}
                                                                    />
                                                                </div>
                                                            </th>
                                                            <td className="id">{product.id}</td>
                                                            <td className="nameFr">
                                                                {editIndex === index ? (
                                                                    <Input
                                                                        type="text"
                                                                        name="nameFr"
                                                                        value={editedProduct.nameFr}
                                                                        onChange={handleInputChange}
                                                                    />
                                                                ) : (
                                                                    product.nameFr
                                                                )}
                                                            </td>
                                                            <td className="nameEn">
                                                                {editIndex === index ? (
                                                                    <Input
                                                                        type="text"
                                                                        name="nameEn"
                                                                        value={editedProduct.nameEn}
                                                                        onChange={handleInputChange}
                                                                    />
                                                                ) : (
                                                                    product.nameEn
                                                                )}
                                                            </td>
                                                            <td>
                                                                <div className="d-flex gap-2">
                                                                    {editIndex === index ? (
                                                                        <>
                                                                            <button className="btn btn-sm btn-success" onClick={() => handleSave(index)}>Save</button>
                                                                            <button className="btn btn-sm btn-danger" onClick={handleCancel}>Cancel</button>
                                                                        </>
                                                                    ) : (
                                                                        <>
                                                                            <button className="btn btn-sm btn-info edit-item-btn" onClick={() => handleEditClick(index)}>Edit</button>
                                                                            <button
                                                                                className="btn btn-sm btn-danger remove-item-btn" onClick={() => {
                                                                                    setProductToDelete(product);
                                                                                    setmodal_standard(true);
                                                                                }}

                                                                            >
                                                                                Remove
                                                                            </button>
                                                                        </>
                                                                    )}
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                            {totalItems === 0 && (
                                                <div className="noresult">
                                                    <div className="text-center">
                                                        <h5 className="mt-2">Sorry! No Result Found</h5>
                                                        <p className="text-muted mb-0">...</p>
                                                    </div>
                                                </div>
                                            )}
                                        </div>

                                        {totalItems > itemsPerPage && (
                                            <div className="d-flex justify-content-end mt-3">
                                                <nav>
                                                    <ul className="pagination">
                                                        {[...Array(totalPages)].map((_, pageIndex) => (
                                                            <li key={pageIndex} className={`page-item ${currentPage === pageIndex + 1 ? 'active' : ''}`}>
                                                                <button className="page-link" onClick={() => handlePageChange(pageIndex + 1)}  >
                                                                    {pageIndex + 1}
                                                                </button>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </nav>
                                            </div>
                                        )}

                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
                {/*start model add*/}
            <Modal isOpen={modal_list} toggle={() => {  setmodal_list(!modal_list);  }}  centered className="modal-lg"  >
                <ModalHeader>
                    Add New Product
                </ModalHeader>
                <ModalBody>
                    <form onSubmit={handleAddProduct}>
                        <div className="mb-3">
                            <label htmlFor="nameFr" className="form-label">Name_FR</label>
                            <input
                                type="text"
                                className="form-control"
                                id="nameFr"
                                name="nameFr"
                                value={newProduct.nameFr}
                                onChange={(e) => setNewProduct({ ...newProduct, nameFr: e.target.value })}
                                required
                            />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="nameEn" className="form-label">Name_EN</label>
                            <input
                                type="text"
                                className="form-control"
                                id="nameEn"
                                name="nameEn"
                                value={newProduct.nameEn}
                                onChange={(e) => setNewProduct({ ...newProduct, nameEn: e.target.value })}
                                required
                            />
                        </div>
                        <ModalFooter>
                            <Button type="button" color="secondary" onClick={() => setmodal_list(false)}>Cancel</Button>
                            <Button type="submit" color="primary">Add Product</Button>
                        </ModalFooter>
                    </form>
                </ModalBody>
            </Modal>

             {/*end model add */}
            {/*start model delete */}
            <Modal isOpen={modal_standard} toggle={() => setmodal_standard(!modal_standard)} className="modal-dialog-centered">

                <CardBody>
                    <div className="text-center">
                        <div className="mb-4">
                            <i className="mdi mdi-alert-circle-outline display-1 text-danger fs-30"></i>
                        </div>
                        <h4 className="alert-heading">
                            Are you sure?
                        </h4>
                        <p className="mb-0">
                            you wont be able to revert this !
                            <br />   <br />
                        </p>
                    </div>
                </CardBody>

                <div className="modal-footer text-center " style={{ justifyContent: "center" }}>
                    <Button color="success" onClick={() => productToDelete && handleRemove(productToDelete.id)}>Yes, delete it !</Button>


                    <button type="button" className="btn btn-danger " onClick={() => { tog_standard(); }}  >
                        Cancel
                    </button>
                </div>
            </Modal>
            {/* end model delete */}
        </React.Fragment>
    );
};

export default ListProducts;
