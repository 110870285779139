import React, { useState, useEffect } from "react";
import { BACKEND_Externe_URL } from "../../config/const.js";
import { Container, Row, Col, Card, Alert, CardBody, Button, Label, Input, FormFeedback, Form, } from "reactstrap";
import axios from "axios";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

//redux
import { useDispatch } from "react-redux";

import withRouter from "../../components/Common/withRouter";

//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb";

// import avatar from "../../assets/images/users/avatar-1.jpg";
// actions
import { editProfile } from "../../store/actions";

const UserProfile = () => {
  document.title = "Profile | Quess Magistrale";

  const dispatch = useDispatch();
  const [userProfile, setUserProfile] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Fetch token from localStorage
  const token = localStorage.getItem("token");
  if (!token) {
    console.error("Token is missing");
  }
  
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  // Fetch profile data from API
  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        const response = await axios.get(`${BACKEND_Externe_URL}connectedUserInfo`, config);
        setUserProfile(response);
        console.log('data:',response);
        setLoading(false);
     
      } catch (error) {
        console.error("Error fetching profile data:", error.response ? error.response.data : error.message);
        setError("Failed to load profile data");
        setLoading(false);
      }
    };

    fetchUserProfile();
  }, []); // Empty array to fetch data only on component mount


  useEffect(() => {
    console.log('userprofiledata:',userProfile);
  }, [userProfile]);
  // Formik setup
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      username: userProfile?.username || "",
      firstname: userProfile?.firstname || "",
      lastname: userProfile?.lastname || "",
      phone: userProfile?.phone || "",
      email: userProfile?.email || "",
    },
    validationSchema: Yup.object({
      username: Yup.string().required("Please Enter Your Username"),
      firstname: Yup.string().required("Please Enter Your First Name"),
      lastname: Yup.string().required("Please Enter Your Last Name"),
      phone: Yup.string().required("Please Enter Your Phone Number"),
      email: Yup.string().email("Invalid email").required("Please Enter Your Email"),
    }),
    onSubmit: (values) => {
      dispatch(editProfile(values));
    },
  });

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumb title="Quess Magistrale" breadcrumbItem="Profile" />

          {loading ? (
            <Alert color="info">Loading Profile...</Alert>
          ) : error ? (
            <Alert color="danger">{error}</Alert>
          ) : (
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <div className="d-flex">
                      <div className="ms-3">
                        <img
                          src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQwR7D1p0O-37w3Fnp9xYLR3G6gMFMbq3z99Q&s"
                          alt=""
                          className="avatar-md rounded-circle img-thumbnail"
                        />
                      </div>
                      <div className="flex-grow-1 align-self-center">
                        <div className="text-muted">
                          <h5>{userProfile?.firstname} {userProfile?.lastname}</h5>
                          <p className="mb-1">{userProfile?.email}</p>
                          <p className="mb-0">Phone: {userProfile?.phone}</p>
                        </div>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}

          <h4 className="card-title mb-4">Change User Information</h4>

          <Card>
            <CardBody>
              <Form
                className="form-horizontal"
                onSubmit={(e) => {
                  e.preventDefault();
                  validation.handleSubmit();
                  return false;
                }}
              >
                <div className="form-group">
                  <Label className="form-label">User Name</Label>
                  <Input
                    name="username"
                    className="form-control"
                    placeholder="Enter User Name"
                    type="text"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.username}
                    invalid={
                      validation.touched.username && validation.errors.username
                        ? true
                        : false
                    }
                  />
                  {validation.touched.username && validation.errors.username ? (
                    <FormFeedback type="invalid">
                      {validation.errors.username}
                    </FormFeedback>
                  ) : null}
                </div>

                <div className="form-group">
                  <Label className="form-label">First Name</Label>
                  <Input
                    name="firstname"
                    className="form-control"
                    placeholder="Enter First Name"
                    type="text"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.firstname}
                    invalid={
                      validation.touched.firstname && validation.errors.firstname
                        ? true
                        : false
                    }
                  />
                  {validation.touched.firstname && validation.errors.firstname ? (
                    <FormFeedback type="invalid">
                      {validation.errors.firstname}
                    </FormFeedback>
                  ) : null}
                </div>

                <div className="form-group">
                  <Label className="form-label">Last Name</Label>
                  <Input
                    name="lastname"
                    className="form-control"
                    placeholder="Enter Last Name"
                    type="text"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.lastname}
                    invalid={
                      validation.touched.lastname && validation.errors.lastname
                        ? true
                        : false
                    }
                  />
                  {validation.touched.lastname && validation.errors.lastname ? (
                    <FormFeedback type="invalid">
                      {validation.errors.lastname}
                    </FormFeedback>
                  ) : null}
                </div>

                <div className="form-group">
                  <Label className="form-label">Phone</Label>
                  <Input
                    name="phone"
                    className="form-control"
                    placeholder="Enter Phone Number"
                    type="text"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.phone}
                    invalid={
                      validation.touched.phone && validation.errors.phone
                        ? true
                        : false
                    }
                  />
                  {validation.touched.phone && validation.errors.phone ? (
                    <FormFeedback type="invalid">
                      {validation.errors.phone}
                    </FormFeedback>
                  ) : null}
                </div>

                <div className="form-group">
                  <Label className="form-label">Email</Label>
                  <Input
                    name="email"
                    className="form-control"
                    placeholder="Enter Email"
                    type="email"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.email}
                    invalid={
                      validation.touched.email && validation.errors.email
                        ? true
                        : false
                    }
                  />
                  {validation.touched.email && validation.errors.email ? (
                    <FormFeedback type="invalid">
                      {validation.errors.email}
                    </FormFeedback>
                  ) : null}
                </div>

                <div className="text-center mt-4">
                  <Button type="submit" color="danger">
                    Update Profile
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(UserProfile);
