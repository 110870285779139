import React, { useState } from "react";
import Creatable from 'react-select/creatable';
import { Card, CardBody, Form, Col, Row, Container } from "reactstrap";
import logobce from "../../assets/images/logo_bce.png";
import logoorganization from "../../assets/images/organization_logo.png";
import './style.css';
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { EditorState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { useNavigate } from 'react-router-dom';
const CreateNewTemplate = () => {
  document.title = "Create New Template | Quess Magistrale";
  const navigate = useNavigate();
  const [concentrations, setConcentrations] = useState([{ id: 1 }]);
  const [authorizeds, setAuthorizeds] = useState([{ id: 1 }]);
  const [formulaRows, setFormulaRows] = useState([{ id: 1 }]);
  //const [equipmentRows, setEquipmentRows] = useState([{ id: 1 }]);
  const [otherRows, setOtherRows] = useState([{ id: 1 }]);
  const [other, setOther] = useState([{ id: 1 }]);
  const [formulaBatch, setFormulaBatch] = useState('');
  const [desiredBatch, setDesiredBatch] = useState('');
  const [unitFormula, setUnitFormula] = useState('Units');
  const [unitDesired, setUnitDesired] = useState('Units');
  //const [columns, setColumns] = useState(['Quantity']);
  const [isColumnAdded, setIsColumnAdded] = useState(false);
  const [columns, setColumns] = useState(["Quantity"]); // Initial column names
  const [equipmentRows, setEquipmentRows] = useState([
    { id: 1, equipment: '', quantity: 120 }
  ]);

  const uploadImageCallBack = (file) => {
    return new Promise(
      (resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          resolve({ data: { link: reader.result } });
        };
        reader.onerror = reject;
        reader.readAsDataURL(file);
      }
    );
  };
  const [activite, setActivite] = useState([
    { id: 1, title: "Additional information on ingredients" },
    { id: 2, title: "Calculations and Measurements" },
    { id: 3, title: "Compounding Method" },
    { id: 4, title: "Quality Control" },
    { id: 5, title: "Packaging" },
    { id: 6, title: "Stability and Storage" },
    { id: 7, title: "Training" },
    { id: 8, title: "References Consulted" },
  ]);
  const [showRemoveColumnButton, setShowRemoveColumnButton] = useState(false)
  // const [modal_standard, setmodal_standard] = useState(false);

  // function tog_standard() {
  //   setmodal_standard(!modal_standard);
  // }
  const handleFormulaBatchChange = (e) => {
    setFormulaBatch(e.target.value);
    setDesiredBatch(e.target.value); // Update desiredBatch in real-time
  };

  const handleDesiredBatchChange = (e) => {
    setDesiredBatch(e.target.value);
    setFormulaBatch(e.target.value); // Update formulaBatch in real-time
  };

  const handleUnitFormulaChange = (e) => {
    setUnitFormula(e.target.value);
    setUnitDesired(e.target.value); // Update unitDesired in real-time
  };

  const handleUnitDesiredChange = (e) => {
    setUnitDesired(e.target.value);
    setUnitFormula(e.target.value); // Update unitFormula in real-time
  };

  const addConcentrationRow = () => {
    setConcentrations([...concentrations, { id: concentrations.length + 1 }]);
  };

  const removeConcentrationRow = (id) => {
    setConcentrations(concentrations.filter(concentration => concentration.id !== id));
  };
  const addAuthorizedRow = () => {
    setAuthorizeds([...authorizeds, { id: authorizeds.length + 1 }]);
  };
  const removeAuthorizedRow = (id) => {
    setAuthorizeds(authorizeds.filter(authorized => authorized.id !== id));
  };
  const addFormulaRow = () => {
    setFormulaRows([...formulaRows, { id: formulaRows.length + 1 }]);
  };
  const removeFormulaRow = (id) => {
    setFormulaRows(formulaRows.filter(formula => formula.id !== id));
  };

  const addOtherRow = () => {
    setOtherRows([...otherRows, { id: otherRows.length + 1 }]);
  };
  const removeOtherRow = (id) => {
    setOtherRows(otherRows.filter(other => other.id !== id));
  };
  const removeActivite = (id) => {
    setActivite(activite.filter((activite) => activite.id !== id));
  };
  const [options, setOptions] = useState([
    { value: 'Compounded Product 1', label: 'Compounded Product 1' },
    { value: 'Compounded Product 2', label: 'Compounded Product 2' },
    { value: 'Compounded Product 3', label: 'Compounded Product 3' }
  ]);

  const handleChange = (newValue, actionMeta) => {
    console.group('Value Changed');
    console.log(newValue);
    console.log(`action: ${actionMeta.action}`);
    console.groupEnd();
  };

  const handleCreate = (inputValue) => {
    const newOption = { value: inputValue, label: inputValue };
    setOptions((prevOptions) => [...prevOptions, newOption]);
    console.log(`Option created: ${inputValue}`);
  };

  const addColumn = () => {
    setColumns([...columns, <input className="form-control" type="text" placeholder="new column"  />]);
    setIsColumnAdded(true);
  };

  const removeColumn = () => {
    setColumns(columns.slice(0, -1));
    setIsColumnAdded(false);
  };
  // const addColumn = () => {
  //   setColumns([...columns, `New Column ${columns.length}`]);
  // };

  // Function to add a new row
  const addEquipmentRow = () => {
    setEquipmentRows([
      ...equipmentRows,
      { id: equipmentRows.length + 1, equipment: '', quantity: 0 }
    ]);
  };

  // Function to remove a row by id
  const removeEquipmentRow = (id) => {
    setEquipmentRows(equipmentRows.filter(row => row.id !== id));
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    navigate('/List-formula-template');
    console.log("Form submitted");
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="Master Formula" breadcrumbItem="Create New Master Formula" />
          <div className="row-form mb-4">
            <Form onSubmit={handleSubmit}>
              <Row className="mb-3 mt-2">
                <Col md={2}>
                  <img src={logobce} alt="" height="24" className="auth-logo logo-dark mx-auto" />
                </Col>
                <Col md={8} className="row">
                  <label className="col-md-2 col-form-label">Compounded</label>
                  <div className="col-md-7">
                    <select className="form-select">
                      <option>Select category</option>
                      <option>Template Sterile</option>
                      <option>Template Non Sterile</option>
                    </select>
                    <p> <span className="float-start">Number : 123456789</span> <span className="float-end"> Version: 1</span></p>
                  </div>
                  <label className="col-md-3 col-form-label">Preparation protocol</label>
                </Col>
                <Col md={2}>
                  <img src={logoorganization} alt="" height="24" className="logo-organization" />
                </Col>
              </Row>
              <Row>
                <Col lg={12}>
                  <Card className="card-bordred">
                    <h5 className="card-header">Header</h5>
                    <div className="text-center"> </div>
                    <CardBody>
                      {concentrations.map((concentration) => (
                        <Row id="row-concentration" key={concentration.id}>
                          <Col lg={5}>
                            <div>
                              <Creatable
                                isClearable
                                onChange={handleChange}
                                onCreateOption={handleCreate}
                                options={options}
                                placeholder="Name of Compounded Product"
                              />
                              {/* <select className="form-select"  name="product">
                              <option>Name of Compounded Product</option>
                              <option>Compounded Product 1</option>
                              <option>Compounded Product 2</option>
                              <option>Compounded Product 3</option>
                            </select> */}
                            </div>
                          </Col>
                          <Col lg={3}>
                            <div>
                              <input className="form-control" type="text" placeholder="Concentration" />
                            </div>
                          </Col>
                          <Col lg={3}>
                            <div>
                              <select className="form-select">
                                <option>Select Symbols</option>
                                <option>Option 1</option>
                                <option>Option 2</option>
                              </select>
                            </div>
                          </Col>
                          <Col lg={1}>
                            <div>
                              {concentration.id === 1 ? (
                                <button
                                  className="btn btn-primary waves-effect waves-light"
                                  type="button"
                                  onClick={addConcentrationRow}
                                >
                                  <i className="fa fa-plus" />
                                </button>
                              ) : (
                                <button
                                  className="btn btn-danger waves-effect waves-light"
                                  type="button"
                                  onClick={() => removeConcentrationRow(concentration.id)}
                                >
                                  <i className="fa fa-minus" />
                                </button>
                              )}
                            </div>
                          </Col>
                        </Row>
                      ))}

                      <Row className="mt-3">
                        <Col lg={5}>
                          <div>
                            <select className="form-select">
                              <option>Pharmaceutical form</option>
                              <option>Option 1</option>
                              <option>Option 2</option>
                            </select>
                          </div>
                        </Col>
                        <Col lg={7}>
                          <div className="row">
                            <label htmlFor="example-date-input" className="col-md-4 col-form-label ms-3"  >  Effective Date: </label>
                            <div className="col-md-6">
                              <input className="form-control" type="date" defaultValue="2024-08-09"
                                id="example-date-input"
                              />
                            </div>
                          </div>
                        </Col>

                      </Row >
                      {authorizeds.map((authorized) => (
                        <Row id="row-authorized" className="mt-3" key={authorized.id}>
                          <Col lg={11}>
                            <div>
                              <select className="form-select">
                                <option>Authorized By</option>
                                <option>User 1</option>
                                <option>User 2</option>
                              </select>
                            </div>
                          </Col>

                          <Col lg={1}>
                            <div>
                              {authorized.id === 1 ? (
                                <button
                                  className="btn btn-primary waves-effect waves-light"
                                  type="button"
                                  onClick={addAuthorizedRow}
                                >
                                  <i className="fa fa-plus" />
                                </button>
                              ) : (
                                <button
                                  className="btn btn-danger waves-effect waves-light"
                                  type="button"
                                  onClick={() => removeAuthorizedRow(authorized.id)}
                                >
                                  <i className="fa fa-minus" />
                                </button>
                              )}
                            </div>
                          </Col>
                        </Row>
                      ))}
                    </CardBody>
                  </Card>
                </Col>

                <Col lg={12}>
                  <Card className="card-bordred">
                    <h5 className="card-header">Formula </h5>
                    <CardBody>
                      <div className="form-check form-check-end">
                        <input className="form-check-input" type="checkbox" defaultValue="" id="defaultCheck2" defaultChecked />
                        <label className="form-check-label" htmlFor="defaultCheck2"  >Batch quantity auto calculat  </label>
                      </div>


                      <Row className="mt-3">
                        <Col lg={3}>
                          <div className="row">
                            <label htmlFor="formula_batch" className="col-md-8 col-form-label ">Formula Batch Size:</label>
                            <div className="col-md-4">
                              <input
                                id="formula_batch"
                                className="form-control"
                                type="text"
                                value={formulaBatch}
                                placeholder="200"
                                onChange={handleFormulaBatchChange}
                                name="formula_batch"
                              />
                            </div>
                          </div>
                        </Col>

                        <Col lg={2}>
                          <div>
                            <select
                              className="form-select"
                              name="unit_formula"
                              value={unitFormula}
                              onChange={handleUnitFormulaChange}
                            >
                              <option value="Units">Units</option>
                              <option value="vials">vials</option>
                              <option value="mls">mls</option>
                              <option value="bags">bags</option>
                            </select>
                          </div>
                        </Col>

                        <Col lg={2}></Col>

                        <Col lg={3}>
                          <div className="row">
                            <label htmlFor="desired_batch" className="col-md-8 col-form-label ">Desired Batch Size:</label>
                            <div className="col-md-4">
                              <input
                                id="desired_batch"
                                className="form-control"
                                type="text"
                                value={desiredBatch}
                                placeholder="200"
                                onChange={handleDesiredBatchChange}
                                name="desired_batch"
                              />
                            </div>
                          </div>
                        </Col>

                        <Col lg={2}>
                          <div>
                            <select
                              className="form-select"
                              name="unit_desired"
                              value={unitDesired}
                              onChange={handleUnitDesiredChange}
                            >
                              <option value="Units">Units</option>
                              <option value="vials">vials</option>
                              <option value="mls">mls</option>
                              <option value="bags">bags</option>
                            </select>
                          </div>
                        </Col>
                      </Row>
                      <Row className="mt-3">
                        <div className="table-responsive">
                          <table className="table table-bordered mb-0">
                            <thead style={{ background: "black" }}>
                              <tr id="formula">
                                <th>Ingredients</th>
                                <th>Manufacture</th>
                                <th>Lot Number</th>
                                <th>Exp Date</th>
                                <th>Quantity</th>
                                <th>Units</th>
                                <th>Quantity Required</th>
                                <th>Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              {formulaRows.map((formula) => (
                                <tr key={formula.id}>
                                  <td>  <select className="form-select">
                                    <option>Ingredient 1</option>
                                    <option>Ingredient 2</option>
                                    <option>Ingredient 3</option>
                                  </select>
                                  </td>
                                  <td className="td-disabled">

                                  </td>
                                  <td className="td-disabled"> </td>
                                  <td className="td-disabled"></td>
                                  <td>  <input className="form-control" type="number" placeholder="1" /></td>
                                  <td>  <select className="form-select">
                                    <option>Units</option>
                                    <option>vials</option>
                                    <option>mls</option>
                                    <option>bags</option>
                                  </select></td>
                                  <td>
                                    <input className="form-control" type="number" placeholder="20" /></td>
                                  <td>
                                    {formula.id === 1 ? (
                                      <button
                                        className="btn btn-primary waves-effect waves-light"
                                        type="button"
                                        onClick={addFormulaRow}
                                      >
                                        <i className="fa fa-plus" />
                                      </button>
                                    ) : (
                                      <button
                                        className="btn btn-danger waves-effect waves-light"
                                        type="button"
                                        onClick={() => removeFormulaRow(formula.id)}
                                      >
                                        <i className="fa fa-minus" />
                                      </button>
                                    )}
                                  </td>
                                </tr>
                              ))}

                            </tbody>
                          </table>
                        </div>
                      </Row>

                    </CardBody>
                  </Card>
                </Col>

                <Col lg={12}>
                  <Card className="card-bordred">
                    <h5 className="card-header">Required equipement/materiels </h5>
                    <CardBody>
                      <p className="card-title-desc"> Indicate all materials and equipment that will be required to compound the sterile preparations.</p>
                      <Row className="mt-3">
                        <div className="table-responsive">
                          <table className="table table-bordered mb-0">
                            <thead style={{ background: "black" }}>
                              <tr id="Equipement">
                                <th>Equipement/materials</th>
                                {columns.map((column, index) => (
                                  <th key={index}>
                                    <Row>
                                    <Col lg={8}>
                                      
                                    {column}</Col>
                                    <Col lg={4}>
                                    {column === 'Quantity' && !isColumnAdded && (
                                      <button
                                        className="btn btn-white btn-add waves-effect waves-light text-end"
                                        type="button"
                                        onClick={addColumn}
                                      >
                                        <i className="fa fa-plus" />
                                      </button>
                                    )}
                                    {column !== 'Quantity' && (
                                      <button
                                        className="btn btn-danger btn-remove text-end"
                                        type="button" style={{ marginLeft: "10%" }}
                                        onClick={removeColumn}
                                      >
                                        <i className="fa fa-minus" />
                                      </button>
                                    )}
                                    </Col>
                                    </Row>
                                  </th>
                                ))}

                                <th>Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              {equipmentRows.map((equipement) => (
                                <tr key={equipement.id}>
                                  <td>
                                    <select className="form-select">
                                      <option>select equipment</option>
                                      <option>equipment 1</option>
                                      <option>equipment 2</option>
                                    </select>
                                  </td>
                                  {columns.map((column, index) => (
                                    <td key={index}>
                                      {column === 'Quantity' ? (
                                        <input
                                          className="form-control"
                                          type="number"
                                          defaultValue={equipement.quantity}
                                        />
                                      ) : (
                                        <input
                                          className="form-control"
                                          type="text"
                                          defaultValue=""
                                        />
                                      )}
                                    </td>
                                  ))}
                                  <td className="text-end">
                                    {equipement.id === 1 ? (
                                      <button
                                        className="btn btn-primary waves-effect waves-light"
                                        type="button"
                                        onClick={addEquipmentRow}
                                      >
                                        <i className="fa fa-plus" />
                                      </button>
                                    ) : (
                                      <button
                                        className="btn btn-danger waves-effect waves-light"
                                        type="button"
                                        onClick={() => removeEquipmentRow(equipement.id)}
                                      >
                                        <i className="fa fa-minus" />
                                      </button>
                                    )}
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </Row>

                    </CardBody>
                  </Card>
                </Col>


                {activite.map((item) => (
                  <Col lg={12} key={item.id}>
                    <Card className="card-bordred">
                      <h5 className="card-header">
                        <Row>
                          <Col md={11}>{item.title}</Col>
                          <Col md={1}>
                            <button
                              className="btn btn-danger waves-effect waves-light float-end"
                              type="button"
                              onClick={() => removeActivite(item.id)}
                            >
                              <i className="fa fa-minus" />
                            </button>
                          </Col>
                        </Row>
                      </h5>
                      <CardBody style={{ background: "#fafafa" }}>
                        <Row>
                          <Col md={12}>
                          <Editor
                    toolbarClassName="toolbarClassName"
                    wrapperClassName="wrapperClassName"
                    editorClassName="editorClassName"
                    placeholder="Start From Here..."
                    editorStyle={{ minHeight: "120px", background: "white" }}
                    toolbar={{
                      options: ["inline", "blockType", "fontSize", "list", "textAlign", "link", "image", "remove", "history"],
                      image: {
                        uploadEnabled: true,
                        uploadCallback: uploadImageCallBack,
                        previewImage: true,
                        alt: { present: true, mandatory: false },
                      },
                    }}
                  />
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                ))}

                {otherRows.map((other) => (
                  <Col lg={12}>
                    <Card className="card-bordred" id="activitieRow" key={other.id} >
                      <h5 className="card-header "> <Row> <Col md={11} className="text-start">
                        <input className="form-control" type="text" placeholder="Title" />
                      </Col> <Col md={1}>  {other.id === 1 ? (
                        <button className="btn btn-primary waves-effect waves-light float-end" type="button" onClick={addOtherRow}  >
                          <i className="fa fa-plus" />  </button>
                      ) : (
                        <button
                          className="btn btn-danger waves-effect waves-light float-end"
                          type="button"
                          onClick={() => removeOtherRow(other.id)}
                        >
                          <i className="fa fa-minus" />
                        </button>
                      )}</Col> </Row> </h5>
                      <CardBody style={{ background: "#fafafa" }} >

                        <Row>
                          <Col md={12} >

                            <Editor toolbarClassName="toolbarClassName"
                              wrapperClassName="wrapperClassName"
                              editorClassName="editorClassName"
                              placeholder="Start From Here..." editorStyle={{ minHeight: '120px', background: "white" }} />

                          </Col>
                        </Row>

                      </CardBody>
                    </Card>

                  </Col>
                ))}
                <Col lg={12} className="fixed-bottom-buttons">
                  <div className="float-end">
                    <button type="submit" className="ms-1 btn btn-success me-3">Save</button>
                    <button type="reset" className="btn btn-info">Cancel</button>
                  </div>
                </Col>

              </Row>
            </Form>
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default CreateNewTemplate;
